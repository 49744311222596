import React, { useContext, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden } from '@mui/material';
import PropTypes from 'prop-types';

import { UserContext } from 'eficia/contexts/UserProvider';
import SidebarMenu from 'eficia/views/template/Sidebar/SidebarMenu';
import { SidebarFooter } from 'eficia/views/template/SidebarFooter';
import { SidebarHeader } from 'eficia/views/template/SidebarHeader';

import { NAV_CONFIG } from './constants';
import { AppSidebarWrapper, SwipeableDrawerWrapper } from './Sidebar.style';

function Sidebar({ sidebarToggleMobile, setSidebarToggleMobile, hideSidebarContent }) {
  const { getSidebarPermissions } = useContext(UserContext);
  useEffect(() => {
    getSidebarPermissions(['display-group-list', 'display-user-list']);
  }, [getSidebarPermissions]);

  const sidebarMenuContent = !hideSidebarContent && (
    <div data-testid="sidebar">
      {NAV_CONFIG.map((list, index) => (
        <SidebarMenu
          key={index}
          component={list.component ? list.component : 'div'}
          filter={list.filter}
          pages={list.content}
          title={list.label}
          style={{
            marginTop: -3
          }}
          setSidebarToggleMobile={setSidebarToggleMobile}
        />
      ))}
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        {/* mobile */}
        <SwipeableDrawerWrapper
          anchor="left"
          open={sidebarToggleMobile}
          onOpen={() => setSidebarToggleMobile(true)}
          onClose={() => setSidebarToggleMobile(false)}
          variant="temporary"
          elevation={4}
        >
          <SidebarHeader
            sidebarToggleMobile={sidebarToggleMobile}
            setSidebarToggleMobile={setSidebarToggleMobile}
          />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
          <SidebarFooter />
        </SwipeableDrawerWrapper>
      </Hidden>

      <Hidden lgDown>
        {/* desktop */}
        <AppSidebarWrapper square open elevation={3}>
          <SidebarHeader />
          <div style={{ height: 'calc(100vh - 80px - 90px)' }}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
          <SidebarFooter />
        </AppSidebarWrapper>
      </Hidden>
    </>
  );
}

Sidebar.propTypes = {
  sidebarToggleMobile: PropTypes.bool,
  setSidebarToggleMobile: PropTypes.func.isRequired,
  hideSidebarContent: PropTypes.bool
};

export default Sidebar;
