import React, { useContext, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import PropTypes from 'prop-types';
import am5LocalesCS from '@amcharts/amcharts5/locales/cs_CZ';
import am5LocalesDA from '@amcharts/amcharts5/locales/da_DK';
import am5LocalesDE from '@amcharts/amcharts5/locales/de_DE';
import am5LocalesEN from '@amcharts/amcharts5/locales/en_US';
import am5LocalesES from '@amcharts/amcharts5/locales/es_ES';
import am5LocalesFI from '@amcharts/amcharts5/locales/fi_FI';
import am5LocalesFR from '@amcharts/amcharts5/locales/fr_FR';
import am5LocalesHU from '@amcharts/amcharts5/locales/hu_HU';
import am5LocalesIT from '@amcharts/amcharts5/locales/it_IT';
import am5LocalesJA from '@amcharts/amcharts5/locales/ja_JP';
import am5LocalesKO from '@amcharts/amcharts5/locales/ko_KR';
import am5LocalesNB from '@amcharts/amcharts5/locales/nb_NO';
import am5LocalesNL from '@amcharts/amcharts5/locales/nl_NL';
import am5LocalesPL from '@amcharts/amcharts5/locales/pl_PL';
import am5LocalesPT from '@amcharts/amcharts5/locales/pt_PT';
import am5LocalesRU from '@amcharts/amcharts5/locales/ru_RU';
import am5LocalesSV from '@amcharts/amcharts5/locales/sv_SE';
import am5LocalesTR from '@amcharts/amcharts5/locales/tr_TR';
import am5LocalesZH from '@amcharts/amcharts5/locales/zh_Hans';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/tr';
import 'moment/locale/zh-cn';

import { UserContext } from 'eficia/contexts/UserProvider';

import { useEditLanguage } from './services/user/useEditLanguage';
import { useFetchTranslations } from './services/translation/useFetchTranslations';
import { useFetchLanguages } from './services/translation/useFetchLanguages';

// Langue par défaut de la Web App (historiquement le français)
const DEFAULT_ISO = 'fr';

// Par défaut nous essayons de configurer les nouvelles langues au best-effort
// Mais il est parfois nécessaire de les configurer manuellement pour certains paramètres
// Lister ici les paramètres à surcharger pour chaque langue
const OVERRIDE_LANGS_SETTINGS = {
  // Théque
  cs: {
    // I18next n'accepte pas 'cs-CS', il faut utiliser 'cs-CZ'
    locale: 'cs-CZ',
    // République Tchèque
    countryCode: 'cz',
    momentLocale: 'cs',
    am5Locales: am5LocalesCS
  },
  // Danois
  da: {
    // Danemark
    countryCode: 'dk',
    momentLocale: 'da',
    am5Locales: am5LocalesDA
  },
  de: {
    am5Locales: am5LocalesDE
  },
  en: {
    locale: 'en-US',
    // Choix de la Grande-Bretagne pour l'anglais
    countryCode: 'gb',
    // Moment.js utilise en-GB pour l'anglais (en-US est aussi disponible)
    momentLocale: 'en-GB',
    am5Locales: am5LocalesEN
  },
  es: {
    am5Locales: am5LocalesES
  },
  // Finlandais
  fi: {
    momentLocale: 'fi',
    am5Locales: am5LocalesFI
  },
  fr: {
    am5Locales: am5LocalesFR
  },
  // Hongrois
  hu: {
    momentLocale: 'hu',
    am5Locales: am5LocalesHU
  },
  it: {
    am5Locales: am5LocalesIT
  },
  // Japonais
  ja: {
    // Japon
    countryCode: 'jp',
    momentLocale: 'ja',
    am5Locales: am5LocalesJA
  },
  // Coréen
  ko: {
    // Corée du Sud
    countryCode: 'kr',
    momentLocale: 'ko',
    am5Locales: am5LocalesKO
  },
  // Norvégien
  no: {
    momentLocale: 'nb',
    am5Locales: am5LocalesNB
  },
  nl: {
    am5Locales: am5LocalesNL
  },
  // Polonais
  pl: {
    momentLocale: 'pl',
    am5Locales: am5LocalesPL
  },
  pt: {
    am5Locales: am5LocalesPT
  },
  // Russe
  ru: {
    momentLocale: 'ru',
    am5Locales: am5LocalesRU
  },
  // Suédois
  sv: {
    // Suède
    countryCode: 'se',
    momentLocale: 'sv',
    am5Locales: am5LocalesSV
  },
  // Turc
  tr: {
    momentLocale: 'tr',
    am5Locales: am5LocalesTR
  },
  // Chinois
  zh: {
    // Chine
    countryCode: 'cn',
    momentLocale: 'zh-cn',
    am5Locales: am5LocalesZH
  }
};

// Factory afin de créer une langue à partir de son code ISO (+ paramètres de surcharge)
const createLang = (iso) => ({
  iso,
  locale: `${iso}-${iso.toUpperCase()}`,
  countryCode: iso,
  momentLocale: iso,
  ...OVERRIDE_LANGS_SETTINGS[DEFAULT_ISO],
  ...(OVERRIDE_LANGS_SETTINGS[iso] || {})
});

// La langue par défaut durant le chargement de la Web App (avant de connaitre celle de l'utilisateur)
// C'est aussi la langue par défaut lorsque l'utilisateur n'a pas encore changé celle-ci
export const DEFAULT_LANG = createLang(DEFAULT_ISO);

// Moment est utilisé pour la gestion des dates et peut afficher une date localisée
moment.locale(DEFAULT_LANG.momentLocale);

// Cette variable va contenir toutes les traductions nécessaire à i18next
// Le téléchargement des traductions est asynchrone et se fait au court du chargement de la Web App
const langResources = {};

if (!i18n.isInitialized) {
  // Très important : ne doit être initié qu'une seule fois
  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: DEFAULT_LANG.locale,
      keySeparator: true,
      resources: langResources,
      returnEmptyString: false,
      parseMissingKeyHandler: (key, defaultValue) => {
        if (!defaultValue) {
          if (Object.keys(langResources).length === 0) {
            // Les traductions sont en cours de chargement
            return '';
          }
          // Affiche la clef de traduction tel quel si elle n'est pas encore traduite **dans la langue par défaut**
          // Pour info, nous copions toutes les clés pas encore traduit dans la langue en cours depuis la langue par défaut dans `useFetchTranslations`
          return key;
        }

        return defaultValue;
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
        useSuspense: true
      }
    });
}

// Version non connectée : pour les pages tampons de Keycloak où des traductions sont nécessaires (exemple : une erreur)
// La langue utilisée est la langue par défaut (note pour plus tard : detecter la langue du navigateur ?)
const AnonymousTranslationContext = React.createContext();
const { Provider: AnonymousProvider } = AnonymousTranslationContext;
function AnonymousTranslationProvider({ children }) {
  const kcLanguageCode = window.kcContext?.locale?.currentLanguageTag;

  const [supportedLangs, setSupportedLangs] = useState();

  const [currentLang, setCurrentLang] = useState(
    kcLanguageCode ? createLang(kcLanguageCode) : DEFAULT_LANG
  );

  const { data: translationData } = useFetchTranslations({
    defaultLocale: DEFAULT_LANG.locale,
    currentLocale: kcLanguageCode || currentLang?.locale
  });
  const { data: languagesData } = useFetchLanguages();

  useEffect(() => {
    if (languagesData?.activeLanguages?.length) {
      setSupportedLangs(
        languagesData.activeLanguages.map((activeLang) => createLang(activeLang.iso))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagesData?.activeLanguages?.length]);

  useEffect(() => {
    if (currentLang && translationData) {
      Object.keys(translationData).forEach((langIso) => {
        langResources[langIso] = { translation: translationData[langIso] };
      });

      // Forcer la prise en compte des traductions fraichement téléchargées
      moment.locale(currentLang.momentLocale);
      i18n.changeLanguage(currentLang.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, translationData]);

  const changeLang = (newLang) => {
    const kcLocale = window.kcContext?.locale?.supported?.find(
      ({ languageTag }) => languageTag === newLang.iso
    );
    if (kcLocale) {
      // Suivre l'URL avec la nouvelle langue qui est déjà construite par Keycloak
      document.location.href = kcLocale.url;
    }
  };

  if (!currentLang || !supportedLangs) {
    // Ne pas continuer si la langue n'est pas encore définie
    return;
  }

  return (
    <AnonymousProvider value={{ currentLang, supportedLangs, changeLang }}>
      {children}
    </AnonymousProvider>
  );
}
AnonymousTranslationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// Version connecté : pour tous les écrans de la Web App
// La langue est prise depuis le Backend, au niveau de l'utilisateur actuellement connecté
const TranslationContext = React.createContext();
const { Provider } = TranslationContext;
function TranslationProvider({ children }) {
  const [supportedLangs, setSupportedLangs] = useState();
  const [currentLang, setCurrentLang] = useState();

  const { data: translationData } = useFetchTranslations({
    defaultLocale: DEFAULT_LANG.locale,
    currentLocale: currentLang?.locale
  });
  const { data: languagesData } = useFetchLanguages();
  const editLanguage = useEditLanguage();

  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (languagesData?.activeLanguages?.length) {
      setSupportedLangs(
        languagesData.activeLanguages.map((activeLang) => createLang(activeLang.iso))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagesData?.activeLanguages?.length]);

  useEffect(() => {
    if (supportedLangs?.length && userData) {
      // Prends la langue de l'utilisateur depuis le Back si la langue est supporté par le Front
      // Sinon fallback sur la langue par défaut
      const newLang =
        supportedLangs.find((supportedLang) => supportedLang.iso === userData.lang) || DEFAULT_LANG;
      setCurrentLang(newLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLangs, userData]);

  useEffect(() => {
    if (currentLang && translationData) {
      Object.keys(translationData).forEach((langIso) => {
        langResources[langIso] = { translation: translationData[langIso] };
      });

      // Forcer la prise en compte des traductions fraichement téléchargées
      moment.locale(currentLang.momentLocale);
      i18n.changeLanguage(currentLang.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, translationData]);

  const changeLang = (newLang, options) => {
    return editLanguage(
      { lang: newLang.iso },
      {
        ...options,
        onSuccess: () => {
          // Il est plus pratique et simple de rafrachir toute la page lors d'un changement de langue
          // Nous prenons l'hypothése qu'un changement de langue est occasionel pour un utilisateur
          // amCharts est exigeant pour changer de langue sans rafraichir la page : il souhaite être redessiné pour prendre en compte la nouvelle langue
          window.location.reload();
        }
      }
    );
  };

  if (!currentLang) {
    // Ne pas continuer si la langue n'est pas encore définie
    return;
  }

  return <Provider value={{ currentLang, supportedLangs, changeLang }}>{children}</Provider>;
}
TranslationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export {
  AnonymousTranslationContext,
  AnonymousTranslationProvider,
  TranslationContext,
  TranslationProvider
};
