import { useCallback } from 'react';
import { useQuery } from 'react-query';

import chatbotClient from 'eficia/services/chatbotClient';

export default function useFetchAiToken(siteId) {
  const client = chatbotClient();

  const fetcher = useCallback(() => {
    if (!siteId) {
      return false;
    }

    return client.chatbot.fetchAiToken({ siteId });
  }, [client.chatbot, siteId]);

  const { data, error, isFetching, refetch } = useQuery(['ai-token-help-button', siteId], fetcher);

  return {
    data: data?.data || null,
    error,
    isFetching,
    refetch
  };
}
