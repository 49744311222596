import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { parseQueryParams } from 'eficia/utilities/query';
import { UserContext } from 'eficia/contexts/UserProvider';
import useFetchVisibleAccesses from './services/access/useFetchVisibleAccesses';

const AccessContext = React.createContext();
const { Provider } = AccessContext;

function AccessProvider({ children }) {
  const { search } = useLocation();
  const { siteId: siteIdFromUrl } = parseQueryParams(search);

  const { userFilters } = useContext(UserContext);
  const { siteId: siteIdFromFilters } = userFilters;

  const { data: allowedAccesses, refetch: refetchAccesses } = useFetchVisibleAccesses({
    siteId: siteIdFromUrl || siteIdFromFilters
  });

  useEffect(() => {
    if (allowedAccesses?.length && siteIdFromUrl) {
      refetchAccesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIdFromUrl]);

  if (!allowedAccesses?.length) {
    // Ne pas continuer sans connaitre les accès possible à présenter à l'utilisateur
    return null;
  }

  return <Provider value={{ allowedAccesses }}>{children}</Provider>;
}

AccessProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AccessProvider, AccessContext };
