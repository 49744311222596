import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BASE_SITE_PATH, ENTITY_SITES_BASE_PATH } from 'eficia/constants/paths';
import { parseQueryParams } from 'eficia/utilities/query';
import { UserContext } from './UserProvider';

import useFetchSite from './services/user/useFetchSite';
import useFetchSites from './services/user/useFetchSites';

const UserSiteContext = createContext();
const { Provider } = UserSiteContext;

function UserSiteProvider({ children }) {
  const { search, pathname } = useLocation();
  const isSitePage = pathname.startsWith(BASE_SITE_PATH);
  const isEntitySitesPage = pathname.startsWith(ENTITY_SITES_BASE_PATH);
  const siteIdFromParams = isEntitySitesPage && pathname.split('/')[4];
  const { siteId: siteIdFromUrl } = parseQueryParams(search);

  const { userFilters, changeUserFilters } = useContext(UserContext);
  const { siteId: siteIdFromFilters } = userFilters;

  const { data: sitesData, isFetching: isFetchingSites } = useFetchSites();
  const { data: sites } = sitesData;

  const { data: siteData, isFetching: isFetchingSite } = useFetchSite(siteIdFromFilters);
  const { data: site } = siteData;

  useEffect(() => {
    if ((isSitePage || isEntitySitesPage) && !site && !siteIdFromFilters) {
      if (isSitePage && siteIdFromUrl) {
        // Prendre le site de l'URL (`?siteId=...`)
        changeUserFilters({ siteId: siteIdFromUrl });
      } else if (isSitePage && sites?.length > 0) {
        // Prendre le premier site de tous les sites de l'organisation
        changeUserFilters({ siteId: sites[0].id });
      } else if (isEntitySitesPage && siteIdFromParams) {
        // Prendre le site des paramètres de l'URL (`/:siteId`)
        changeUserFilters({ siteId: siteIdFromParams });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, siteIdFromFilters, siteIdFromUrl, siteIdFromParams, sites]);

  return (
    <Provider
      value={{
        sitesData,
        isFetchingSites,
        siteData,
        isFetchingSite
      }}
    >
      {children}
    </Provider>
  );
}

UserSiteProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { UserSiteContext, UserSiteProvider };
