import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Tooltip as TooltipUi } from '@mui/material';

const useStyles = makeStyles(() => ({
  customBg: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    whiteSpace: 'pre-line',
    maxWidth: 220
  }
}));

export default function Tooltip({
  children,
  title,
  placement = 'top-start',
  leaveDelay,
  disableTouchListener = false,
  onClose,
  open = undefined,
  ...rest
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const classes = useStyles();
  return (
    <TooltipUi
      enterDelay={100}
      leaveDelay={500}
      onClose={onClose}
      disableTouchListener={disableTouchListener}
      placement={isMobile ? 'bottom' : placement}
      title={title}
      /* LS-1061: Le composant Tooltip de Material UI réagit differement si `open` est défini ou pas (defaultProps est utiliser ?) */
      {...(typeof open === 'boolean' ? { open } : {})}
      classes={{ tooltip: classes.customBg }}
      {...rest}
    >
      {children}
    </TooltipUi>
  );
}

Tooltip.propTypes = {
  leaveDelay: PropTypes.number,
  disableTouchListener: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  placement: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
