import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Fab, Menu } from '@mui/material';

import QuestionWhite from 'assets/eficia/icons/question-white.svg';
import { Icon } from 'eficia/components/atoms/Icon';
import { useMenu } from 'eficia/components/molecules/Menu';
import { CalendlyModal } from 'eficia/components/organisms/Calendly';
import { BASE_SITE_PATH } from 'eficia/constants/paths';
import { UserGroupsContext } from 'eficia/contexts/UserGroupsProvider';
import { UserSiteContext } from 'eficia/contexts/UserSiteProvider';
import ContactFormModal from 'eficia/views/modal/ContactFormModal';

import { HelpButtonMenuNonEC } from './components/HelpButtonMenuNonEC';
import { HelpButtonMenuWithEC } from './components/HelpButtonMenuWithEC';

export default function HelpButton() {
  const { pathname } = useLocation();
  const isSitePage = pathname.startsWith(BASE_SITE_PATH);

  const [isFormContactModalOpen, setIsFormContactModalOpen] = useState(false);
  const [isFormContactECModalOpen, setIsFormContactECModalOpen] = useState(false);
  const [energyCenterLang, setEnergyCenterLang] = useState();
  const [calendlyUrl, setCalendlyUrl] = useState();
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  const { t } = useTranslation();

  const { siteData } = useContext(UserSiteContext);
  const { data: site } = siteData;

  const { sitesByGroupsData } = useContext(UserGroupsContext);
  const { data: groups } = sitesByGroupsData;

  const isHandledByEc =
    (isSitePage && !!site?.isHandledByEc && !!site?.address?.countryId) ||
    (!isSitePage && !!groups?.hasAtLeastOneSiteHandledByEc);

  const actionMenu = useMenu();

  const onClose = () => {
    actionMenu.close();
  };

  const openFormContactModal = (isHandledByEc, lang) => {
    setEnergyCenterLang(lang);
    onClose();
    if (isHandledByEc) {
      setIsFormContactECModalOpen(true);
    } else {
      setIsFormContactModalOpen(true);
    }
  };

  const openCalendlyModal = (url) => {
    onClose();
    setCalendlyUrl(url);
    setIsCalendlyModalOpen(true);
  };

  return (
    <>
      <div data-testid="help-button">
        <Fab
          onClick={(e) => {
            actionMenu.open(e);
          }}
          style={{
            boxShadow: 'none',
            backgroundColor: actionMenu.anchorElement
              ? 'var(--text-caption)'
              : 'var(--text-action)',
            width: 48,
            height: 48
          }}
        >
          {actionMenu.anchorElement ? (
            <Icon size={20} name="close" />
          ) : (
            <img src={QuestionWhite} width="32" height="32" alt="" />
          )}
        </Fab>
      </div>
      {actionMenu.anchorElement && (
        <Menu
          variant="menu"
          sx={{
            '& .MuiPaper-root': {
              /* `overflow` permet d'avoir le menu des langues du Help Button en dehors de celui-ci */
              overflow: 'visible',
              /* `top` et `bottom` permettent d'avoir le contenu du Help Button toujours tout en bas, */
              /* même quand on affiche le numero de téléphone, ce qui agrandi le contenu du Help Button */
              top: 'auto !important',
              bottom: 90,
              opacity: 0,
              visibility: 'hidden',
              '&:has([data-testid="help-button-content"])': {
                visibility: 'visible'
              }
            }
          }}
          disableScrollLock
          anchorEl={actionMenu.anchorElement}
          keepMounted
          open={Boolean(actionMenu.anchorElement)}
          anchorOrigin={{
            vertical: -8,
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          MenuListProps={{
            variant: 'menu'
          }}
          onClose={onClose}
        >
          <div style={{ width: 320 }} data-testid="help-button-content">
            {isHandledByEc ? (
              <HelpButtonMenuWithEC
                currentCountry={site?.address?.countryId}
                openCalendlyModal={openCalendlyModal}
                openFormContactModal={openFormContactModal}
              />
            ) : (
              <HelpButtonMenuNonEC openFormContactModal={openFormContactModal} />
            )}
          </div>
        </Menu>
      )}
      {isCalendlyModalOpen && calendlyUrl && (
        <CalendlyModal
          calendlyUrl={calendlyUrl}
          onClose={() => {
            setIsCalendlyModalOpen(false);
          }}
        />
      )}
      {isFormContactModalOpen && (
        <ContactFormModal
          isHandledByEc={false}
          headerTitle={t('contact_form_modal.title')}
          submitLabel={t('contact_form_modal.submit')}
          typeButton="submit"
          isModalOpen={isFormContactModalOpen}
          onClose={() => setIsFormContactModalOpen(false)}
        />
      )}
      {isFormContactECModalOpen && (
        <ContactFormModal
          energyCenterLang={energyCenterLang}
          isHandledByEc
          headerTitle={t('contact_form_modal.title')}
          submitLabel={t('contact_form_modal.submit')}
          typeButton="submit"
          isModalOpen={isFormContactECModalOpen}
          onClose={() => setIsFormContactECModalOpen(false)}
        />
      )}
    </>
  );
}
