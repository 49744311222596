import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import { Icon } from 'eficia/components/atoms/Icon';

import Tooltip from './Tooltip';
import { IconContainer } from './Tooltip.style';

export default function TooltipIcon({ title, content, link, ...rest }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  function handleClose() {
    if (isMobile) {
      setIsOpen(false);
    }
  }
  function handleOpen() {
    if (isMobile) {
      setIsOpen(true);

      setTimeout(() => {
        handleClose();
      }, 8000);
    }
  }

  const tooltipDisplay = (
    <div style={{ fontWeight: 'normal' }}>
      {title && <div style={{ fontWeight: '500', fontSize: 16 }}>{title}</div>}
      <div style={{ marginTop: 8 }}>{content}</div>
      {link && (
        <div style={{ marginTop: 8 }}>
          <a
            style={{ color: 'lightgrey', textDecoration: 'underline' }}
            target="_blank"
            href={link}
          >
            {t('tooltip.see_more')}
          </a>
        </div>
      )}
    </div>
  );

  return isMobile ? (
    <div>
      <Tooltip
        title={<ClickAwayListener onClickAway={handleClose}>{tooltipDisplay}</ClickAwayListener>}
        placement="right"
        interactive
        disableTouchListener={isMobile}
        open={isMobile ? isOpen : undefined}
        onClose={handleClose}
        {...rest}
      >
        <IconContainer onClick={handleOpen}>
          <Icon name="information" size={16} />
        </IconContainer>
      </Tooltip>
    </div>
  ) : (
    <Tooltip
      title={tooltipDisplay}
      placement="right"
      interactive
      enterTouchDelay={0}
      leaveTouchDelay={1500}
      {...rest}
    >
      <IconContainer onClick={handleOpen}>
        <Icon name="information" size={16} />
      </IconContainer>
    </Tooltip>
  );
}

TooltipIcon.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string
};
