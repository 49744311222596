import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: relative;

  .Button-underline {
    & > * {
      text-decoration: underline;
      text-underline-offset: 10px;
    }
  }

  .Button-skip {
    color: #a2a5a8;
    background: rgba(0, 0, 0, 0.03);
  }

  .btn-full-width {
    width: 100%;
  }

  .Button-success {
    background: #32bea6;
    width: 250px;
    height: 50px;

    & > * {
      font-size: 18px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 2px;
    font-size: 14px !important;
  }
  .Button-action {
    svg {
      opacity: 0.6;
      width: 14px;
      height: 14px;
      font-size: 14px !important;
    }
  }
  .eficia-link-button:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }

  .MuiButton-containedPrimary {
    color: white;
  }

  .MuiButton-gradient {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    position: relative;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.88);

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      margin: 0;
    }

    &.MuiButton-sizeLarge {
      padding: 12px 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    /* SOURCE: https://dev.to/afif/border-with-gradient-and-radius-387f */
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 40px;
      padding: 3px;
      background: linear-gradient(10deg, #f2a93b, #e977a1, #6687ff, #2d69f4, #6787ff);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: 4px;
      border-radius: 40px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.95),
        rgba(255, 255, 255, 0)
      );
      animation: 0.75s linear gradient-wave;
      background-size: 200% 200%;
      animation-direction: reverse;
      animation-iteration-count: 1;
      display: none;
    }

    &:hover {
      &::after {
        display: block;
      }
    }

    &:active {
      opacity: 0.75;
    }
  }

  .Button-secondary {
    background-color: #cbd3dd;
    color: ${({ theme }) => theme.palette.textAction};
  }

  .Mui-disabled {
    background-color: ${({ theme }) => theme.palette.grey[200]} !important;
    color: var(--text-disabled) !important;
    border: 1px solid transparent !important;

    svg {
      filter: var(--text-disabled-filter) !important;
    }
  }

  &.icon-only {
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      margin: 0;
    }
  }

  @keyframes gradient-wave {
    0% {
      background-position: -400% 0%;
    }
    100% {
      background-position: -200% 0%;
    }
  }
`;
