import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Badge from 'eficia/components/atoms/Badge/Badge';

import './index.css';

export default function Tags({ tags = [], texts, badge, maxWidth }) {
  const { t } = useTranslation();

  const renderLabel = (tags) => {
    const tagCount = tags.length;
    const textToDisplay = badge ? <Badge label={t(tags[0].label)} /> : t(tags[0].label);

    return tagCount === 1 ? textToDisplay : t('multiselect.selected_elements', { count: tagCount });
  };

  return (
    <ul className="tag-list">
      <li
        className="tag-item"
        style={{
          maxWidth: maxWidth ? `calc(${maxWidth - 50}px)` : 'auto'
        }}
      >
        {tags.length === 0 ? (
          <span className="placeholder">{texts.placeholder || 'Choose...'}</span>
        ) : (
          renderLabel(tags)
        )}
      </li>
    </ul>
  );
}

Tags.propTypes = {
  maxWidth: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  texts: PropTypes.shape({
    labelRemove: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  badge: PropTypes.bool
};
