import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Hidden, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import sidebarLogo from 'assets/eficia/logos/sidebar_logo.svg';
import { HOME_PATH } from 'eficia/constants/paths';

import { AppHeader, MenuButtonContainer } from './Header.style';

function Header({ sidebarToggleMobile, setSidebarToggleMobile }) {
  const { isPageTitlePresent } = useContext(LayoutContext);

  return (
    <AppHeader
      style={{
        backgroundColor: isPageTitlePresent ? 'white' : 'transparent'
      }}
      elevation={1}
      data-testid="header"
    >
      <Hidden lgUp>
        {/* mobile */}
        <MenuButtonContainer>
          <IconButton
            color="inherit"
            onClick={() => setSidebarToggleMobile(!sidebarToggleMobile)}
            size="large"
          >
            {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        </MenuButtonContainer>
      </Hidden>
      <Hidden lgDown>
        {/* desktop */}
        <Link to={HOME_PATH} style={{ padding: '24px 22px 24px 22px' }}>
          <img src={sidebarLogo} height="32" alt="Eficia" />
        </Link>
      </Hidden>
    </AppHeader>
  );
}

Header.propTypes = {
  setSidebarToggleMobile: PropTypes.func,
  sidebarToggleMobile: PropTypes.bool
};

export default Header;
