import React from 'react';

import { ErrorWidget } from 'eficia/components/organisms/ErrorWidget';
import { siteProps } from 'eficia/types/sites';

import { Chatbot } from './chatbot/Chatbot';
import useFetchAiToken from './services/chatbot/useFetchAiToken';

export default function AiChatbot({ site }) {
  const { data, isFetching, error } = useFetchAiToken(site.id);

  if (isFetching) {
    // Mettre un loader qui s'affiche après un certain temps pour ne pas surcharger la UI ?
    return null;
  }
  if (error) {
    return <ErrorWidget backgroundColor="transparent" />;
  }

  return <Chatbot aiToken={data?.data?.token} site={site} />;
}

AiChatbot.propTypes = {
  site: siteProps.isRequired
};
