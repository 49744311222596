import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { PROFILE_HOME_PATH, PROFILE_EDIT_PATH } from 'eficia/constants/paths';

const ProfileHomePage = lazyWithRetry(() => import('eficia/views/profile/ProfileHomePage'));
const ProfileEditPage = lazyWithRetry(() => import('eficia/views/profile/ProfileEditPage'));

const removeRoutePrefix = (path) => {
  const pathPrefix = PROFILE_HOME_PATH;

  return path.replace(pathPrefix, '');
};

export default function ProfileRoutes() {
  return (
    <Routes>
      <Route path={removeRoutePrefix(PROFILE_HOME_PATH)} element={<ProfileHomePage />} />
      <Route path={removeRoutePrefix(PROFILE_EDIT_PATH)} element={<ProfileEditPage />} />
    </Routes>
  );
}
