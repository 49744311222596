// === errors
export const EXPIRED_LINK_ERROR_PATH = '/expired-link';
export const ORGANIZATION_NOT_FOUND_PATH = '/organization-not-found';
export const IS_UNKNOWN_PATH = '/is-unknown';

// === Login
export const REGISTER_PATH = '/register';
export const LOGIN_PATH = '/login';
export const FINALIZE_REGISTER_PATH = '/finalize-register';

// groups
export const BASE_GROUPS_PATH = '/understand/groups';
export const DASHBOARD_GROUP_HOME_PATH = '/understand/groups/dashboard';
export const ELECTRICITY_CURVE_GROUPS_HOME_PATH = '/understand/groups/electricity_curve';
export const GAS_CURVE_GROUPS_HOME_PATH = '/understand/groups/gas_curve';
export const WATER_CURVE_GROUPS_HOME_PATH = '/understand/groups/water_curve';
export const AIR_CURVE_GROUPS_HOME_PATH = '/understand/groups/air_curve';
export const OTHER_EQUIPMENTS_GROUPS_HOME_PATH = '/understand/groups/other_equipments';
export const HVAC_CURVE_GROUPS_HOME_PATH = '/understand/groups/hvac_curve';
export const ELECTRICITY_ACCOUNTING_GROUPS_HOME_PATH = '/understand/groups/electricity_accounting';
export const GAS_ACCOUNTING_GROUPS_HOME_PATH = '/understand/groups/gas_accounting';
export const WATER_ACCOUNTING_GROUPS_HOME_PATH = '/understand/groups/water_accounting';
export const MISCELLANEOUS_GROUPS_HOME_PATH = '/understand/groups/miscellaneous';
export const DEPRECATED_CURVE_GROUPS_HOME_PATH = '/understand/groups/curve'; // Redirect
export const DEPRECATED_ELECTRICITY_METERS_GROUPS_RANKING_PATH =
  '/understand/groups/electricity_meters_ranking'; // Redirect

// site
export const BASE_SITE_PATH = '/understand/site';
export const DASHBOARD_SITE_HOME_PATH = '/understand/site/dashboard';
export const BAS_ACCESS_SITE_HOME_PATH = '/understand/site/bas_access';
export const ELECTRICITY_CURVE_SITE_HOME_PATH = '/understand/site/electricity_curve';
export const GAS_CURVE_SITE_HOME_PATH = '/understand/site/gas_curve';
export const WATER_CURVE_SITE_HOME_PATH = '/understand/site/water_curve';
export const AIR_CURVE_SITE_HOME_PATH = '/understand/site/air_curve';
export const OTHER_EQUIPMENTS_SITE_HOME_PATH = '/understand/site/other_equipments';
export const HVAC_CURVE_SITE_HOME_PATH = '/understand/site/hvac_curve';
export const ELECTRICITY_ACCOUNTING_SITE_HOME_PATH = '/understand/site/electricity_accounting';
export const GAS_ACCOUNTING_SITE_HOME_PATH = '/understand/site/gas_accounting';
export const WATER_ACCOUNTING_SITE_HOME_PATH = '/understand/site/water_accounting';
export const MISCELLANEOUS_SITE_HOME_PATH = '/understand/site/miscellaneous';
export const DEPRECATED_CURVE_SITE_HOME_PATH = '/understand/site/curve'; // Redirect
export const DEPRECATED_HARMONY_CURVE_SITE_HOME_PATH = '/understand/site/harmony_curve'; // Redirect
export const DEPRECATED_HARMONY_PILOT_SITE_HOME_PATH = '/understand/site/harmony_pilot'; // Redirect

// === Alertes
export const ALERTS_HOME_PATH = '/alerts';
export const DEPRECATED_ALERTS_MANAGEMENT_PATH = '/alerts/management';
export const ALERTS_HISTORY_DETAILS_PATH = '/alerts/history/:siteId/:alertId';
export const ALERTS_CREATE_PATH = '/alerts/create';
export const ALERTS_EDIT_PATH = '/alerts/:alertId/:type/edit';
export const ALERTS_COPY_PATH = '/alerts/:alertId/:type/copy';

// === Organisation
export const ENTITY_HOME_PATH = '/entity';
export const ENTITY_SITES_PATH = '/entity/sites';
export const ENTITY_SITES_CREATE_PATH = '/entity/sites/create';
export const ENTITY_SITES_BASE_PATH = '/entity/sites/details';
export const ENTITY_SITES_DETAILS_PATH = `${ENTITY_SITES_BASE_PATH}/:siteId`;
export const ENTITY_SITES_EDIT_PATH = `${ENTITY_SITES_BASE_PATH}/:siteId/edit`;
export const ENTITY_SITES_ELECTRICITY_METERS_CREATE_PATH = `${ENTITY_SITES_BASE_PATH}/:siteId/electricity-meters/create`;

export const ENTITY_GROUPS_PATH = '/entity/groups';
export const ENTITY_USERS_PATH = '/entity/users';
export const ENTITY_ORGANIZATION_PATH = '/entity/organization';

// === News
export const NEWS_PATH = '/news';
export const NEWS_DASHBOARD_PATH = '/news/dashboard';

// === Profile
export const PROFILE_HOME_PATH = '/profile';
export const PROFILE_EDIT_PATH = '/profile/edit';

// === Page d'accueil
export const HOME_PATH = DASHBOARD_SITE_HOME_PATH;
