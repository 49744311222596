import PropTypes from 'prop-types';

export const errorProps = PropTypes.shape({
  message: PropTypes.string,
  type: PropTypes.string,
  ref: PropTypes.shape({
    focus: PropTypes.func,
    name: PropTypes.string
  })
});
