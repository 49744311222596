import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchSitesEC() {
  const client = eficiaClient();
  let formattedSites = null;

  const fetcher = useCallback(() => {
    return client.sites.fetch({ filters: { handledByEc: true } });
  }, [client.sites]);

  const { data, isFetching } = useQuery(['sites-single-select-fetch-ec'], fetcher);

  if (data?.data.data) {
    formattedSites = data.data.data.map((data) => {
      return {
        label: data.name,
        value: data.id
      };
    });
  }

  return {
    data: formattedSites || [],
    isFetching
  };
}
