import styled from 'styled-components';

// Largeur de la Right Sidebar (en px)
const RIGHT_SIDEBAR_WIDTH = 288;

export const DesktopOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
  transform: translateZ(0);
`;

export const DesktopAppear = styled.div`
  display: flex;
  width: ${({ $visibleRightSidebar }) => ($visibleRightSidebar ? `${RIGHT_SIDEBAR_WIDTH}px` : 0)};
  height: 100vh;
  animation: ${({ $visibleRightSidebar }) => ($visibleRightSidebar ? 'opening' : 'closing')};
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  overflow: hidden;
  border-left: 1px solid var(--border-default);
  box-sizing: content-box;
  background-color: white;
  z-index: 9999;

  @keyframes opening {
    0% {
      width: 0px;
    }
    100% {
      width: ${RIGHT_SIDEBAR_WIDTH}px;
    }
  }

  @keyframes closing {
    0% {
      width: ${RIGHT_SIDEBAR_WIDTH}px;
    }
    100% {
      width: 0px;
    }
  }
`;

export const DesktopContainer = styled.div`
  display: flex;
  background: linear-gradient(
    186deg,
    rgba(221, 231, 253, 0.2) -3.48%,
    rgba(251, 234, 238, 0.2) 52.62%,
    rgba(252, 239, 227, 0.2) 105.46%
  );
  height: 100vh;
  width: ${RIGHT_SIDEBAR_WIDTH}px;
  position: fixed;
  flex-direction: column;
  overflow: hidden;
`;

export const DesktopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-default);
  padding: 12px 12px 8px 20px;
`;

export const DesktopBody = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
`;
