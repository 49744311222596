import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BASE_GROUPS_PATH, BASE_SITE_PATH } from 'eficia/constants/paths';
import { UserContext } from './UserProvider';

import useFetchSitesByGroups from './services/user/useFetchSitesByGroups';

const UserGroupsContext = createContext();
const { Provider } = UserGroupsContext;

function UserGroupsProvider({ children }) {
  const { pathname } = useLocation();
  const isSitePage = pathname.startsWith(BASE_SITE_PATH);
  const isGroupsPage = pathname.startsWith(BASE_GROUPS_PATH);
  const isOtherPage = !isSitePage && !isGroupsPage;

  const { userGroups, userOperator } = useContext(UserContext);

  const {
    data: sitesByGroupsData,
    labels: sitesByGroupsLabels,
    withGasMeters: sitesByGroupsWithGasMeters,
    isFetching: isFetchingSitesByGroups,
    error: sitesByGroupsError
  } = useFetchSitesByGroups({
    // Récupérer la liste des groupes :
    // - Vue parc : uniquement les filtrers (liste de groupes avec operateur)
    // - Autre vue que site et parc : tous les groupes (sans aucun filtre)
    // eslint-disable-next-line no-nested-ternary
    groupIds: isGroupsPage ? userGroups : isOtherPage ? [] : null,
    operator: isGroupsPage || !isSitePage ? userOperator : null
  });

  return (
    <Provider
      value={{
        // Représente toutes les données des sites par groupes (données brutes)
        sitesByGroupsData,
        // Il s'agit des données auquels on a garder uniquement les `label`s et `value`s
        // Ces données proviennent de `sitesByGroupsData`
        sitesByGroupsLabels,
        // Au moins un compteur de gaz par site : On a garder uniquement les `label`s et `value`s
        // Ces données proviennent de `sitesByGroupsData`
        sitesByGroupsWithGasMeters,
        isFetchingSitesByGroups,
        sitesByGroupsError
      }}
    >
      {children}
    </Provider>
  );
}

UserGroupsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { UserGroupsContext, UserGroupsProvider };
