import React, { forwardRef, Suspense } from 'react';
import { createHashRouter, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { SnackbarProvider } from 'notistack';

import { Notification } from 'eficia/components/molecules/Notification';
import { ErrorPage } from 'eficia/components/organisms/ErrorPage';
import PulseModal from 'eficia/components/organisms/Modal/PulseModal';
import {
  ALERTS_HOME_PATH,
  BASE_GROUPS_PATH,
  BASE_SITE_PATH,
  ENTITY_HOME_PATH,
  IS_UNKNOWN_PATH,
  NEWS_PATH,
  ORGANIZATION_NOT_FOUND_PATH,
  PROFILE_HOME_PATH
} from 'eficia/constants/paths';
import { AccessProvider } from 'eficia/contexts/AccessProvider';
import { LocationProvider } from 'eficia/contexts/LocationProvider';
import { NotificationProvider } from 'eficia/contexts/NotificationProvider';
import {
  AnonymousTranslationProvider,
  TranslationProvider
} from 'eficia/contexts/TranslationProvider';
import { UserGroupsProvider } from 'eficia/contexts/UserGroupsProvider';
import { UserProvider } from 'eficia/contexts/UserProvider';
import { UserSiteProvider } from 'eficia/contexts/UserSiteProvider';
import AlertsRoutes from 'eficia/routes/pages/AlertsRoutes';
import EntityRoutes from 'eficia/routes/pages/EntityRoutes';
import GroupsRoutes from 'eficia/routes/pages/GroupsRoutes';
import NewsRoutes from 'eficia/routes/pages/NewsRoutes';
import ProfileRoutes from 'eficia/routes/pages/ProfileRoutes';
import SiteRoutes from 'eficia/routes/pages/SiteRoutes';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';
import { AiButton } from 'eficia/views/template/AiButton';
import { HelpButton } from 'eficia/views/template/HelpButton';
import { LeftSidebar } from 'eficia/views/template/LeftSideBar';
import { RightSidebar } from 'eficia/views/template/RightSidebar';
import { SideActions } from 'eficia/views/template/SideActions';

import '../../assets/base.scss';

const LoginKCPage = lazyWithRetry(() => import('eficia/views/login/LoginKCPage'));
const AuthorizeKCPage = lazyWithRetry(() => import('eficia/views/login/AuthorizeKCPage'));
const LoginAsPage = lazyWithRetry(() => import('eficia/views/login/LoginAsPage'));
const OrganizationNotFoundPage = lazyWithRetry(
  () => import('eficia/views/error/OrganizationNotFoundPage')
);
const IsUnknownPage = lazyWithRetry(() => import('eficia/views/error/IsUnknownPage'));

const ForwardNotification = forwardRef((props, ref) => {
  return <Notification ref={ref} notification={props} />;
});

function AppLayout() {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  return (
    <SnackbarProvider
      Components={{
        custom: ForwardNotification
      }}
      maxSnack={5}
      preventDuplicate
    >
      <NotificationProvider>
        {[
          '/expired-link',
          '/forbidden-access',
          '/login',
          '/register',
          '/login-as',
          '/keycloak-login',
          '/keycloak-authorize',
          '/'
        ].includes(location.pathname) ? (
          <AnonymousTranslationProvider>
            <Suspense>
              <Outlet />
            </Suspense>
          </AnonymousTranslationProvider>
        ) : (
          <AnimatePresence>
            <UserProvider>
              <UserSiteProvider>
                <UserGroupsProvider>
                  <AccessProvider>
                    <TranslationProvider>
                      <LocationProvider>
                        <RightSidebar>
                          <LeftSidebar>
                            <Suspense fallback={<PulseModal />}>
                              <motion.div
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                              >
                                <Outlet />
                              </motion.div>
                            </Suspense>

                            <SideActions>
                              <AiButton />
                              <HelpButton />
                            </SideActions>
                          </LeftSidebar>
                        </RightSidebar>
                      </LocationProvider>
                    </TranslationProvider>
                  </AccessProvider>
                </UserGroupsProvider>
              </UserSiteProvider>
            </UserProvider>
          </AnimatePresence>
        )}
      </NotificationProvider>
    </SnackbarProvider>
  );
}

function AppRoutes() {
  const router = createHashRouter([
    {
      path: '/',
      // Page d'erreur de plus haut niveau : la sidebar n'est pas disponible à ce niveau
      errorElement: <ErrorPage />,
      element: <AppLayout />,
      children: [
        // Routes non connectées
        {
          path: '/',
          element: <LoginKCPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/login',
          element: <LoginKCPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/keycloak-login',
          element: <LoginKCPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/keycloak-authorize',
          element: <AuthorizeKCPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/register',
          element: <LoginKCPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/login-as',
          element: <LoginAsPage />,
          errorElement: <ErrorPage />
        },

        // Routes connectées
        {
          path: ORGANIZATION_NOT_FOUND_PATH,
          element: <OrganizationNotFoundPage />,
          errorElement: <ErrorPage />
        },
        {
          path: IS_UNKNOWN_PATH,
          element: <IsUnknownPage />,
          errorElement: <ErrorPage />
        },
        {
          path: `${BASE_SITE_PATH}/*`,
          element: <SiteRoutes />,
          errorElement: <ErrorPage />
        },
        {
          path: `${BASE_GROUPS_PATH}/*`,
          element: <GroupsRoutes />,
          errorElement: <ErrorPage />
        },
        {
          path: `${ENTITY_HOME_PATH}/*`,
          element: <EntityRoutes />,
          errorElement: <ErrorPage />
        },
        {
          path: `${ALERTS_HOME_PATH}/*`,
          element: <AlertsRoutes />,
          errorElement: <ErrorPage />
        },
        {
          path: `${NEWS_PATH}/*`,
          element: <NewsRoutes />,
          errorElement: <ErrorPage />
        },
        {
          path: `${PROFILE_HOME_PATH}/*`,
          element: <ProfileRoutes />,
          errorElement: <ErrorPage />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}

export default AppRoutes;
