import styled from 'styled-components';

// Hauteur du panneau en % de la taille du viewport (vh)
export const PANEL_HEIGHT_PERCENT = 90;

// Durée de l'animation d'ouverture en secondes
const PANEL_ANIMATION_DURATION_SECONDS = 0.25;

// Index de superposition du panneau
export const PANEL_ZINDEX = 9999;

// Hauteur de la zone de drag en pixels
export const PANEL_DRAG_HEIGHT = 20;

// Point de rupture en % de la taille du viewport (vh)
export const PANEL_DRAG_COLLAPSE_BREAKPOINT_PERCENT = 20;

export const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: ${PANEL_ZINDEX};
  background: rgba(0, 0, 0, 0.5);
`;

export const MobileAppear = styled.div`
  position: absolute;
  top: 0;
  top: 10vh;
  display: ${({ $visibleRightSidebar }) => ($visibleRightSidebar ? 'flex' : 'none')};
  width: 100%;
  height: ${PANEL_HEIGHT_PERCENT}vh;
  animation: ${({ $visibleRightSidebar }) => ($visibleRightSidebar ? 'opening' : 'none')};
  animation-duration: ${PANEL_ANIMATION_DURATION_SECONDS}s;
  animation-iteration-count: 1;
  overflow: hidden;
  background: white;
  z-index: ${PANEL_ZINDEX};

  @keyframes opening {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  background: linear-gradient(
    186deg,
    rgba(221, 231, 253, 0.2) -3.48%,
    rgba(251, 234, 238, 0.2) 52.62%,
    rgba(252, 239, 227, 0.2) 105.46%
  );
  height: ${PANEL_HEIGHT_PERCENT}vh;
  width: 100%;
  position: fixed;
  flex-direction: column;
  overflow: hidden;
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-default);
  padding: 0 12px 8px 20px;
`;

export const MobileBody = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
`;

export const MobileDrag = styled.div`
  display: flex;
  width: 100%;
  height: ${PANEL_DRAG_HEIGHT}px;
  border-radius: 8px 8px 0px 0px;
  background: #f9fafe;
  animation: ${({ $visibleRightSidebar }) => ($visibleRightSidebar ? 'opening' : 'none')};
  animation-duration: ${PANEL_ANIMATION_DURATION_SECONDS}s;
  animation-iteration-count: 1;
  height: ${PANEL_HEIGHT_PERCENT}vh;

  &:after {
    content: ' ';
    display: flex;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    height: 4px;
    border-radius: 999px;
    margin-top: 8px;
    background-color: var(--text-action);
  }
`;
