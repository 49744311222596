import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';

export default function SideActions({ children }) {
  const { isFixedActionsFooterPresent } = useContext(LayoutContext);

  if (isFixedActionsFooterPresent) {
    return null;
  }

  return (
    <div
      data-testid="side-actions"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'end',
        gap: 16,
        position: 'fixed',
        zIndex: 900,
        bottom: 24,
        right: 24
      }}
    >
      {children}
    </div>
  );
}

SideActions.propTypes = {
  children: PropTypes.node
};
