import React, { useContext, useEffect, useState } from 'react';
import reactable from 'reactablejs';
import { Typography } from '@mui/material';

import { ErrorWidget } from 'eficia/components/organisms/ErrorWidget';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import ErrorBoundary from 'eficia/views/error/ErrorBoundary';

import {
  MobileAppear,
  MobileBody,
  MobileContainer,
  MobileDrag,
  MobileHeader,
  PANEL_DRAG_COLLAPSE_BREAKPOINT_PERCENT,
  PANEL_DRAG_HEIGHT,
  PANEL_HEIGHT_PERCENT,
  PANEL_ZINDEX
} from './MobileRightSidebar.style';

// eslint-disable-next-line react/prop-types
const Drag = ({ getRef, y }) => {
  const { rightSidebarContent } = useContext(LayoutContext);

  return (
    <div
      ref={getRef}
      style={{
        position: 'absolute',
        // Le "draggable" doit être PANEL_DRAG_HEIGHT (px) au-dessus du panneau (et le suivre)
        top: ((100 - PANEL_HEIGHT_PERCENT) * window.innerHeight) / 100 + y - PANEL_DRAG_HEIGHT,
        left: 0,
        width: '100%',
        height: `${PANEL_HEIGHT_PERCENT}vh`,
        zIndex: PANEL_ZINDEX,
        touchAction: 'none'
      }}
    >
      <MobileDrag $visibleRightSidebar={!!rightSidebarContent} />
    </div>
  );
};
const DragReactable = reactable(Drag);

export default function MobileRightSidebar() {
  const { rightSidebarContent, setRightSidebarContent } = useContext(LayoutContext);
  const [coordinate, setCoordinate] = useState({ y: 0 });

  useEffect(() => {
    setCoordinate({ y: 0 });
  }, [rightSidebarContent]);

  return (
    <>
      {!!rightSidebarContent && (
        <DragReactable
          resizable={{
            edges: { left: false, right: false, bottom: true, top: true }
          }}
          // L'utilisateur déplace le panneau
          onResizeMove={(e) => {
            const { top } = e.deltaRect;

            setCoordinate((prev) => {
              return {
                ...prev,
                // On met à jour la position du panneau
                // Mais uniquement vers le bas (ou position initiale)
                y: Math.max(0, prev.y + top)
              };
            });
          }}
          // L'utilisateur a relâché le panneau
          onResizeEnd={(e) => {
            const { top } = e.deltaRect;

            if (
              coordinate.y + top >
              (PANEL_DRAG_COLLAPSE_BREAKPOINT_PERCENT * window.innerHeight) / 100
            ) {
              // Le point de rupture vertical est atteint, on ferme le panneau
              return setRightSidebarContent(null);
            }

            setCoordinate((prev) => {
              return {
                ...prev,
                // On remet le panneau à sa position initiale
                y: 0
              };
            });
          }}
          {...coordinate}
        />
      )}
      <MobileAppear
        style={{ marginTop: coordinate.y }}
        $visibleRightSidebar={!!rightSidebarContent}
        data-testid={
          rightSidebarContent ? 'opened-mobile-right-sidebar' : 'closed-mobile-right-sidebar'
        }
      >
        {!!rightSidebarContent && (
          <MobileContainer>
            <MobileHeader>
              <Typography variant="h3" style={{ fontWeight: 600, fontSize: 18 }}>
                <i className="fa-regular fa-sparkles" />
                <span style={{ paddingLeft: 8 }}>{rightSidebarContent.title}</span>
              </Typography>
              <Typography
                variant="h3"
                style={{ fontWeight: 600, fontSize: 18, cursor: 'pointer', padding: 8 }}
                onClick={() => setRightSidebarContent(null)}
              >
                <i className="fa-solid fa-xmark" />
              </Typography>
            </MobileHeader>
            <MobileBody>
              <ErrorBoundary errorElement={<ErrorWidget backgroundColor="transparent" />}>
                {rightSidebarContent.component}
              </ErrorBoundary>
            </MobileBody>
          </MobileContainer>
        )}
      </MobileAppear>
    </>
  );
}
