import { EFICIA_BASE_API } from './constants';
import axiosConfig from './axiosConfig';

const queryConfig = ({
  api,
  endpoint,
  isConnected,
  baseUrl = EFICIA_BASE_API,
  params = {},
  method = 'post',
  endpointSuffix = '',
  config = {}
}) => {
  if (!isConnected)
    return {
      isFetching: false
    };

  return import('./endpoints').then(async ({ urls }) => {
    return axiosConfig[method](`${baseUrl}${urls[api][endpoint]}${endpointSuffix}`, params, config);
  });
};

export default queryConfig;
