import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IconLink, TooltipLink } from './TooltipButton.style';
import Tooltip from './Tooltip';

export default function TooltipButton({ children, onTooltipClick, placement = 'top', ...rest }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState(false);

  function handleClose() {
    if (isMobile) {
      setIsOpen(false);
    }
  }
  function handleOpen() {
    if (isMobile) {
      setIsOpen(true);

      setTimeout(() => {
        handleClose();
      }, 8000);
    }
  }

  return (
    <Tooltip
      title={
        onTooltipClick ? (
          <TooltipLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onTooltipClick();
              handleClose();
            }}
          >
            {children}
          </TooltipLink>
        ) : (
          <div>{children}</div>
        )
      }
      placement={placement}
      disableTouchListener={isMobile}
      open={isMobile ? isOpen : undefined}
      onClose={handleClose}
      {...rest}
    >
      <IconLink
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleOpen();
        }}
      >
        <i className="fa-regular fa-circle-info" />
      </IconLink>
    </Tooltip>
  );
}

TooltipButton.propTypes = {
  children: PropTypes.node.isRequired,
  onTooltipClick: PropTypes.func,
  placement: PropTypes.string
};
