import styled from 'styled-components';

export const IconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  color: var(--text-action) !important;
  transition: background-color 0.5s;
  cursor: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }
`;

export const TooltipLink = styled.a`
  color: lightgrey !important;
  text-decoration: underline !important;
`;
