import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'eficia/contexts/UserProvider';
import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchSites() {
  const client = eficiaClient();
  const { isUnknown, organizationNotFound } = useContext(UserContext);

  const fetcher = useCallback(() => {
    if (isUnknown || organizationNotFound) {
      return null;
    }

    return client.sites.fetchFull();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.sites]);

  const { data, error, isFetching } = useQuery(['user-sites'], fetcher);

  return {
    data: {
      data: data?.data.data,
      meta: data?.data.meta
    },
    error,
    isFetching
  };
}
