import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
/* eslint-disable camelcase */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { Menu, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Button } from 'eficia/components/atoms/Button';

import Input from './input';
import { Label, MultiSelectContainer } from './styles/MultiSelect.style';
import Tags from './tags';
import TagsMenu from './tags-menu';
import Tree from './tree';
import TreeManager from './tree-manager';
import keyboardNavigation from './tree-manager/keyboardNavigation';
import Trigger from './trigger';
import { clientIdGenerator, isOutsideClick } from './utils';

import './index.css';

// Workaround afin d'avoir le hook de traduction dans un composant de classe
function Translation({ translationKey }) {
  const { t } = useTranslation();

  return <>{t(translationKey)}</>;
}
Translation.propTypes = {
  translationKey: PropTypes.string.isRequired
};

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchModeOn: false,
      currentFocus: undefined,
      selectAllChecked: false
    };
    this.clientId = props.id || clientIdGenerator.get(this);
  }

  UNSAFE_componentWillMount() {
    this.initNewProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initNewProps(
      nextProps,

      // si une recherche est active, on doit transmetre les informations au treeManager
      this.treeManager && {
        searchMaps: this.treeManager.searchMaps,
        matchTree: this.treeManager.matchTree,
        tree: this.treeManager.tree
      }
    );
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  initNewProps = ({ data, mode, showDropdown, searchPredicate }, treeManager) => {
    this.treeManager = new TreeManager({
      data,
      mode,
      rootPrefixId: this.clientId,
      searchPredicate
    });
    if (treeManager) {
      if (treeManager.matchTree) {
        this.treeManager.tree = treeManager.tree;
        this.treeManager.searchMaps = treeManager.searchMaps;
        this.treeManager.matchTree = treeManager.matchTree;
      }
    }
    this.setState((prevState) => {
      const currentFocusNode =
        prevState.currentFocus && this.treeManager.getNodeById(prevState.currentFocus);
      if (currentFocusNode) {
        currentFocusNode._focused = true;
      }
      return {
        showDropdown: /initial|always/.test(showDropdown) || prevState.showDropdown === true,
        ...this.treeManager.getTreeAndTags()
      };
    });
  };

  resetSearchState = () => {
    // clear the search criteria and avoid react controlled/uncontrolled warning
    if (this.searchInput) {
      this.searchInput.value = '';
    }

    return {
      tree: this.treeManager.restoreNodes(), // restore the tree to its pre-search state
      searchModeOn: false,
      allNodesHidden: false
    };
  };

  handleClick = (e, callback) => {
    this.setState((prevState) => {
      const { showDropdown: showDropdownProps, onFocus, onBlur } = this.props;
      // keep dropdown active when typing in search box
      const showDropdown =
        showDropdownProps === 'always' || this.keepDropdownActive || !prevState.showDropdown;

      if (showDropdown) onFocus();
      else onBlur();

      return !showDropdown ? { showDropdown, ...this.resetSearchState() } : { showDropdown };
    }, callback);
  };

  handleOutsideClick = (e) => {
    const { showDropdown } = this.props;
    if (showDropdown === 'always' || !isOutsideClick(e, this.node)) {
      return;
    }

    this.handleClick();
  };

  onInputChange = (value) => {
    const { keepChildrenOnSearch, keepTreeOnSearch } = this.props;
    const { allNodesHidden, tree } = this.treeManager.filterTree(
      value,
      keepTreeOnSearch,
      keepChildrenOnSearch
    );
    const searchModeOn = value.length > 0;

    // set value of selectAll
    const isAllNodeChecked = this.treeManager.isAllNodeChecked(tree);

    this.setState({
      tree,
      searchModeOn,
      allNodesHidden,
      selectAllChecked: isAllNodeChecked
    });
  };

  onTagRemove = (id, isKeyboardEvent) => {
    const { tags: prevTags } = this.state;
    this.onCheckboxChange(id, false, (tags) => {
      if (!isKeyboardEvent) return;

      keyboardNavigation.getNextFocusAfterTagDelete(id, prevTags, tags, this.searchInput).focus();
    });
  };

  onNodeToggle = (id) => {
    const { searchModeOn } = this.state;
    const { onNodeToggle } = this.props;
    this.treeManager.toggleNodeExpandState(id);
    const tree = searchModeOn ? this.treeManager.matchTree : this.treeManager.tree;
    this.setState({ tree });
    typeof onNodeToggle === 'function' && onNodeToggle(this.treeManager.getNodeById(id));
  };

  onCheckboxChange = (id, checked, callback, e) => {
    const { currentFocus, searchModeOn, checkedIds, showDropdown: showDropdownState } = this.state;
    const { keepOpenOnSelect, mode, clearSearchOnChange } = this.props;

    this.treeManager.setNodeCheckedState(id, checked);
    let { tags } = this.treeManager;
    const isSingleSelect = ['simpleSelect'].indexOf(mode) > -1;
    const showDropdown = isSingleSelect && !keepOpenOnSelect ? false : showDropdownState;
    const currentFocusNode = currentFocus && this.treeManager.getNodeById(currentFocus);
    const node = this.treeManager.getNodeById(id);

    if (!tags.length) {
      this.treeManager.restoreDefaultValues();
      tags = this.treeManager.tags;
    }

    // Save id when press shiftKey
    let newCheckedIds = [];
    if (e && e.shiftKey) {
      newCheckedIds = [...checkedIds, id];
    } else {
      newCheckedIds = [id];
    }

    const tree = searchModeOn ? this.treeManager.matchTree : this.treeManager.tree;

    // set value of selectAll

    const isAllNodeChecked = this.treeManager.isAllNodeChecked(tree);

    const nextState = {
      tree,
      tags,
      showDropdown,
      currentFocus: id,
      selectAllChecked: isAllNodeChecked,
      checkedIds: newCheckedIds
    };

    if ((isSingleSelect && !showDropdown) || clearSearchOnChange) {
      Object.assign(nextState, this.resetSearchState());
    }

    if (isSingleSelect && !showDropdown) {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    keyboardNavigation.adjustFocusedProps(currentFocusNode, node);
    this.setState(nextState, () => {
      callback && callback(tags);

      // Check when press shiftKey
      if (newCheckedIds.length > 1) {
        const idsToCheck = {};
        let i = 0;
        tree.forEach((value) => {
          if (newCheckedIds.includes(value._id)) {
            idsToCheck[i] = value._id;
          }
          i += 1;
        });

        const idsToUpdate = [];
        let isBetween = false;
        tree.forEach((value) => {
          if (value._id === Object.values(idsToCheck)[0]) {
            isBetween = true;
          }
          if (isBetween) {
            if (!value._children) {
              idsToUpdate.push(value._id);
            }
            if (value._id === Object.values(idsToCheck)[1]) {
              isBetween = false;
            }
          }
        });
        idsToUpdate.map((idParam) => this.onCheckboxChange(idParam, true));
        this.setState({ checkedIds: [] });
      }
    });

    const { onChange } = this.props;
    onChange(node, tags);
  };

  onAction = (nodeId, action) => {
    const { onAction } = this.props;
    onAction(this.treeManager.getNodeById(nodeId), action);
  };

  onInputFocus = () => {
    this.keepDropdownActive = true;
  };

  onInputBlur = () => {
    this.keepDropdownActive = false;
  };

  handleSetAnchor = (e) => {
    const { onClick } = this.props;
    if (onClick) onClick();

    this.anchorEl = e.currentTarget;
  };

  onTrigger = (e) => {
    this.handleClick(e, () => {
      const { showDropdown, tree } = this.state;
      // If the dropdown is shown after key press, focus the input
      if (showDropdown) {
        this.searchInput && this.searchInput.focus();
      }

      const isAllNodeChecked = this.treeManager.isAllNodeChecked(tree);
      this.setState({
        selectAllChecked: isAllNodeChecked
      });
    });
  };

  onKeyboardKeyUp = (e) => {
    const { tree } = this.state;
    e.preventDefault();

    if (e.key === 'Backspace') {
      const isAllNodeChecked = this.treeManager.isAllNodeChecked(tree);
      this.setState({ selectAllChecked: isAllNodeChecked });
    }
  };

  onKeyboardKeyDown = (e) => {
    const { readOnly, mode } = this.props;
    const { showDropdown, searchModeOn, currentFocus } = this.state;
    const tm = this.treeManager;
    const tree = searchModeOn ? tm.matchTree : tm.tree;

    if (!showDropdown && (keyboardNavigation.isValidKey(e.key, false) || /^\w$/i.test(e.key))) {
      // Triggers open of dropdown and retriggers event
      e.persist();
      this.handleClick(null, () => this.onKeyboardKeyDown(e));
      if (/\w/i.test(e.key)) return;
    } else if (showDropdown && keyboardNavigation.isValidKey(e.key, true)) {
      const newFocus = tm.handleNavigationKey(
        currentFocus,
        tree,
        e.key,
        readOnly,
        !searchModeOn,
        this.onCheckboxChange,
        this.onNodeToggle
      );
      if (newFocus !== currentFocus) {
        this.setState({ currentFocus: newFocus }, () => {
          const ele = document && document.getElementById(`${newFocus}_li`);
          ele && ele.scrollIntoView();
        });
      }
    } else if (showDropdown && ['Escape', 'Tab'].indexOf(e.key) > -1) {
      if (mode === 'simpleSelect' && tree.has(currentFocus)) {
        this.onCheckboxChange(currentFocus, true);
      } else {
        // Triggers close
        this.handleClose();
      }
      return;
    } else {
      return;
    }
    e.preventDefault();
  };

  handleClose = () => {
    // Triggers close
    this.keepDropdownActive = false;
    this.handleClick();

    const { onClose } = this.props;
    const { tags } = this.state;

    if (onClose) {
      onClose(tags.map((item) => item.value));
    }
  };

  handleSelectAllClick = (e) => {
    const { tree } = this.state;
    const { onChange } = this.props;

    // Get all ids of tree
    const allIds = [];
    tree.forEach((item) => {
      allIds.push(item._id);
    });

    // Update checked value
    this.treeManager.restoreNodesChecked(e.target.checked, allIds);
    const { tags } = this.treeManager;

    this.setState({
      tags,
      tree,
      currentFocus: null,
      selectAllChecked: e.target.checked
    });
    onChange(tree, tags);
  };

  render() {
    const {
      disabled,
      readOnly,
      mode,
      texts,
      tabIndex,
      className,
      keepTreeOnSearch,
      keepChildrenOnSearch,
      onSubmit,
      submitDisabled,
      badge,
      onOperatorChange,
      operator,
      operatorButtons,
      direction = 'bottom',
      backgroundColor = '#fff',
      isMobile,
      withTagsMenu = false,
      menuWidth,
      menuFullWidth = false,
      autoFocus = true,
      shrink = false,
      withSelectAll,
      selectStyle,
      onClose,
      'data-testid': dataTestId,
      withSearch = true
    } = this.props;

    const {
      showDropdown,
      currentFocus,
      tags,
      selectAllChecked,
      allNodesHidden,
      searchModeOn,
      tree
    } = this.state;

    const activeDescendant = currentFocus ? `${currentFocus}_li` : undefined;

    const commonProps = {
      disabled,
      readOnly,
      activeDescendant,
      texts,
      mode,
      clientId: this.clientId,
      dropdownNode: this.node,
      withSelectAll
    };
    const searchInput = (
      <div
        style={{
          padding: '8px 16px',
          borderBottom: '1px solid #CBD5E1'
        }}
      >
        <Input
          autoFocus={autoFocus}
          className="search"
          inputRef={(el) => {
            this.searchInput = el;
          }}
          onInputChange={this.onInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          onKeyUp={this.onKeyboardKeyUp}
          onKeyDown={this.onKeyboardKeyDown}
          dataTestId={dataTestId}
          {...commonProps}
        />
      </div>
    );

    const openArrow = direction === 'right' ? <ArrowRight /> : <ArrowDropUpIcon />;

    // on recupere la taille de l'input pour limiter le nombre de caractere affichable
    let maxWidth = 150;
    const ele = document && document.getElementById(this.clientId);
    if (ele) maxWidth = ele.offsetWidth;

    return (
      <MultiSelectContainer
        id={this.clientId}
        className={[className && className, clsx({ showDropdown }), 'react-dropdown-tree-select']
          .filter(Boolean)
          .join(' ')}
        ref={(node) => {
          this.node = node;
        }}
        style={selectStyle}
        onClick={this.handleSetAnchor}
        data-testid={dataTestId || undefined}
        data-total-nbr-of-options={tree?.size || 0}
        data-selected-nbr-of-options={tags?.length || 0}
        data-selected-options={tags?.map((tag) => tag.value).join(',')}
      >
        {(tags.length > 0 || shrink) && (
          <Label style={{ background: backgroundColor }}>{texts.label}</Label>
        )}
        <div
          className={['dropdown', mode === 'simpleSelect' && 'simple-select']
            .filter(Boolean)
            .join(' ')}
        >
          <Trigger
            onTrigger={this.onTrigger}
            showDropdown={showDropdown}
            {...commonProps}
            tags={tags}
            tabIndex={tabIndex}
          >
            <Tags
              badge={badge}
              tags={tags}
              onTagRemove={this.onTagRemove}
              maxWidth={maxWidth}
              {...commonProps}
            />
            {!showDropdown ? <ArrowDropDownIcon /> : openArrow}
          </Trigger>
          {showDropdown && (
            <Menu
              className={`dropdown-content ${isMobile && 'dropdown-content-mobile'}`}
              variant="menu"
              open={showDropdown}
              anchorEl={this.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              MenuListProps={{
                variant: 'menu',
                disablePadding: true
              }}
              onClose={this.handleClose}
            >
              <div
                className={`dropdown-content ${isMobile && 'dropdown-content-mobile'}`}
                style={{
                  width: menuWidth || (menuFullWidth ? `${this.node.offsetWidth}px` : 'auto'),
                  maxWidth: isMobile
                    ? '90vw'
                    : menuWidth || (menuFullWidth ? `${this.node.offsetWidth}px` : 'auto')
                }}
              >
                {mode !== 'simpleSelect' && withTagsMenu && (
                  <TagsMenu
                    operator={operator}
                    onOperatorChange={onOperatorChange}
                    operatorButtons={operatorButtons}
                    tags={tags}
                    placeholder={
                      texts.tagPlaceholder ? texts.tagPlaceholder : 'Selectionner une option'
                    }
                    onRemove={this.onCheckboxChange}
                    badge={badge}
                    dataTestId={dataTestId}
                  />
                )}
                {withSearch && searchInput}
                {allNodesHidden ? (
                  <div className="no-matches">
                    <Typography variant="subtitle1">
                      {texts.noMatches || 'No matches found'}
                    </Typography>
                  </div>
                ) : (
                  <Tree
                    selectAllChecked={selectAllChecked}
                    onSelectAllClick={this.handleSelectAllClick}
                    data={tree}
                    keepTreeOnSearch={keepTreeOnSearch}
                    keepChildrenOnSearch={keepChildrenOnSearch}
                    searchModeOn={searchModeOn}
                    onAction={this.onAction}
                    onCheckboxChange={this.onCheckboxChange}
                    onNodeToggle={this.onNodeToggle}
                    mode={mode}
                    dataTestId={dataTestId}
                    {...commonProps}
                  />
                )}
                {onSubmit && (
                  <div style={{ padding: 4 }}>
                    <Button
                      disabled={submitDisabled}
                      style={{ width: '100%' }}
                      size="small"
                      label={<Translation translationKey="multiselect.button.validate" />}
                      className="mt-2"
                      onClick={() => {
                        onSubmit();
                        this.keepDropdownActive = false;
                        this.handleClick();
                      }}
                    />
                  </div>
                )}
              </div>
            </Menu>
          )}
        </div>
      </MultiSelectContainer>
    );
  }
}

MultiSelect.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  clearSearchOnChange: PropTypes.bool,
  keepTreeOnSearch: PropTypes.bool,
  keepChildrenOnSearch: PropTypes.bool,
  keepOpenOnSelect: PropTypes.bool,
  texts: PropTypes.shape({
    tagPlaceholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    inlineSearchPlaceholder: PropTypes.string,
    noMatches: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelRemove: PropTypes.string
  }),
  showDropdown: PropTypes.oneOf(['default', 'initial', 'always']),
  className: PropTypes.string,
  onChange: PropTypes.func,
  onAction: PropTypes.func,
  onNodeToggle: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  mode: PropTypes.oneOf(['multiSelect', 'simpleSelect']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  searchPredicate: PropTypes.func,
  tabIndex: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  submitDisabled: PropTypes.bool,
  badge: PropTypes.bool,
  operator: PropTypes.string,
  operatorButtons: PropTypes.array,
  onOperatorChange: PropTypes.func,
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
  isMobile: PropTypes.bool,
  withTagsMenu: PropTypes.bool,
  menuWidth: PropTypes.number,
  menuFullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  autoFocus: PropTypes.bool,
  shrink: PropTypes.bool,
  withSelectAll: PropTypes.bool,
  selectStyle: PropTypes.object,
  'data-testid': PropTypes.string,
  withSearch: PropTypes.bool
};

MultiSelect.defaultProps = {
  onAction: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  texts: {},
  showDropdown: 'default',
  tabIndex: 0
};

export default MultiSelect;
