import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'eficia/contexts/UserProvider';
import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchSite(siteId) {
  const client = eficiaClient();
  const { isUnknown, organizationNotFound } = useContext(UserContext);

  const fetcher = useCallback(() => {
    if (isUnknown || organizationNotFound) {
      return null;
    }
    if (!siteId) {
      return false;
    }

    return client.sites.fetchOne(siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.sites, siteId]);

  const { data, error, isFetching } = useQuery(['user-site', siteId], fetcher);

  return {
    data: {
      data: data?.data?.data || null
    },
    error,
    isFetching
  };
}
