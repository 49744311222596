import React, { useContext } from 'react';
import { Typography } from '@mui/material';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import { ErrorWidget } from 'eficia/components/organisms/ErrorWidget';
import ErrorBoundary from 'eficia/views/error/ErrorBoundary';

import {
  DesktopAppear,
  DesktopContainer,
  DesktopHeader,
  DesktopBody
} from './DesktopRightSidebar.style';

export default function DesktopRightSidebar() {
  const { rightSidebarContent, setRightSidebarContent } = useContext(LayoutContext);

  return (
    <DesktopAppear
      $visibleRightSidebar={!!rightSidebarContent}
      data-testid={
        rightSidebarContent ? 'opened-desktop-right-sidebar' : 'closed-desktop-right-sidebar'
      }
    >
      {!!rightSidebarContent && (
        <DesktopContainer>
          <DesktopHeader>
            <Typography variant="h3" style={{ fontWeight: 600, fontSize: 18 }}>
              <i className="fa-regular fa-sparkles" />
              <span style={{ paddingLeft: 8 }}>{rightSidebarContent.title}</span>
            </Typography>
            <Typography
              variant="h3"
              style={{ fontWeight: 600, fontSize: 18, cursor: 'pointer', padding: 8 }}
              onClick={() => setRightSidebarContent(null)}
            >
              <i className="fa-solid fa-xmark" />
            </Typography>
          </DesktopHeader>
          <DesktopBody>
            <ErrorBoundary errorElement={<ErrorWidget backgroundColor="transparent" />}>
              {rightSidebarContent.component}
            </ErrorBoundary>
          </DesktopBody>
        </DesktopContainer>
      )}
    </DesktopAppear>
  );
}
