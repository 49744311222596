import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@mui/material';

import { MenuWrapper } from './styles/Menu.style';

export default function Menu({ anchorElement, children, onClose, position = 'center', style }) {
  return (
    <MenuWrapper
      style={style}
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onClose}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: position
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: position
      }}
    >
      <div className="overflow-hidden dropdown-menu-md">
        <List className="flex-column">{children}</List>
      </div>
    </MenuWrapper>
  );
}

Menu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorElement: PropTypes.object,
  children: PropTypes.node,
  onClose: PropTypes.func,
  position: PropTypes.string,
  style: PropTypes.object
};
