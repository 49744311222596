import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from 'eficia/components/atoms/Button';
import {
  BASE_SITE_PATH,
  DASHBOARD_SITE_HOME_PATH,
  ENTITY_SITES_BASE_PATH
} from 'eficia/constants/paths';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import { UserSiteContext } from 'eficia/contexts/UserSiteProvider';

import AiChatbot from 'eficia/views/template/AiButton/AiChatbot';

export default function AiButton() {
  const { pathname } = useLocation();
  const isSitePage = pathname.startsWith(BASE_SITE_PATH);
  const isSiteDashboard = pathname.startsWith(DASHBOARD_SITE_HOME_PATH);
  const isEntitySitesPage = pathname.startsWith(ENTITY_SITES_BASE_PATH);

  const { t } = useTranslation();

  const { rightSidebarContent, setRightSidebarContent } = useContext(LayoutContext);

  const { siteData } = useContext(UserSiteContext);
  const { data: site } = siteData;

  if (!site?.hasAiActivated) {
    // La IA n'est pas activé sur ce site
    return null;
  }

  const isButtonVisible = (isSitePage && !isSiteDashboard) || isEntitySitesPage;
  if (!isButtonVisible) {
    // Le bouton n'est visible :
    // - Que sur les pages de site (hors Dashboard car il y a déjà une bannière)
    // - Que sur les détails d'un site (via le bouton "Configurer le site")
    return null;
  }

  return (
    <Button
      startIcon={<i className="fa-regular fa-sparkles" />}
      label={t('ai_button.action')}
      variant="gradient"
      size="large"
      onClick={() =>
        setRightSidebarContent(
          !rightSidebarContent
            ? { title: t('ai_button.right_sidebar.title'), component: <AiChatbot site={site} /> }
            : null
        )
      }
      data-testid="ai-button"
    />
  );
}
