import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';

import { errorProps } from 'eficia/types/react-hook-form/reactHookForm';

import { TextFieldUi } from './Input.style';

export default function Input({
  label,
  onChange,
  adornment,
  error,
  autoComplete,
  disableUnderline,
  ...rest
}) {
  const InputProps = {
    endAdornment: adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : ''
  };

  if (disableUnderline) {
    InputProps.disableUnderline = disableUnderline;
  }

  return (
    <TextFieldUi
      InputProps={InputProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        'data-testid': rest.name,
        'data-iserror': !!error,
        onChange
      }}
      fullWidth
      size="small"
      label={label}
      variant="outlined"
      error={!!error}
      autoComplete={autoComplete || 'chrome-off'}
      {...rest}
    />
  );
}

Input.propTypes = {
  disableUnderline: PropTypes.bool,
  /** label of adornment */
  adornment: PropTypes.any,
  /** error object */
  error: errorProps,
  /** label of input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** onchange function */
  onChange: PropTypes.func,
  /** placeholder of input */
  placeholder: PropTypes.string,
  /** autocomplete of input */
  autoComplete: PropTypes.string
};
