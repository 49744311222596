import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchVisibleAccesses({ siteId }) {
  const client = eficiaClient();

  const fetcher = useCallback(async () => {
    // Entrer en mode dégradé si le fetch échoue
    // Ceci afin d'éviter de bloquer l'affichage de la page
    const fallbackData = {
      data: {
        data: [
          'sites.dashboard',
          'sites.consumption.electricity',
          'sites.consumption.gas',
          'groups.dashboard',
          'groups.consumption.electricity',
          'groups.consumption.gas'
        ]
      }
    };

    try {
      const response = await client.dataVisualization.fetchVisibleAccesses({
        siteId
      });
      if (!response?.data?.data?.length) {
        return fallbackData;
      }
      return response;
    } catch (error) {
      return fallbackData;
    }
  }, [client.dataVisualization, siteId]);

  const { data, error, isFetching, refetch } = useQuery(['user-accesses'], fetcher);

  return {
    data: data?.data?.data || [],
    error,
    isFetching,
    refetch
  };
}
