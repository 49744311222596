import React from 'react';
import PropTypes from 'prop-types';

import InputLoader from 'eficia/components/atoms/Loader/InputLoader';

import FormMultiSelect from '../forms/FormMultiSelect';
import useFetchSites from './services/useFetchSitesEC';

export default function SitesECFormSingleSelect({ label, placeholder, formProps, name, ...rest }) {
  const { data: sites, isFetching } = useFetchSites();
  const { watch } = formProps;

  const siteIds = watch('siteId');

  return (
    <div>
      {isFetching ? (
        <InputLoader label={label} />
      ) : (
        <FormMultiSelect
          key={`${sites.length}-${siteIds[0]}`}
          formProps={formProps}
          name={name}
          data={sites}
          label={label}
          placeholder={placeholder}
          {...rest}
        />
      )}
    </div>
  );
}

SitesECFormSingleSelect.propTypes = {
  formProps: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired
};
