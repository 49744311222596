import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseQueryParams, stringifyQuery } from 'eficia/utilities/query';
import PropTypes from 'prop-types';

import { ErrorPage } from 'eficia/components/organisms/ErrorPage';

import useChangeOrganization from './services/user/useChangeOrganization';
import { useFetchConnectedUserData } from './services/user/useFetchConnectedUserData';
import useFetchOrganizations from './services/user/useFetchOrganizations';
import useGetPermissions from './services/user/useGetPermissions';

const UserContext = React.createContext();
const { Provider } = UserContext;

function UserProvider({ children }) {
  const [orgIsLoading, setOrgIsLoading] = useState(false);
  const [pageIsLoaded, setPageIsLoaded] = useState(false);

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const { search, pathname } = useLocation();
  const searchObj = parseQueryParams(search);
  const { organizationId, siteId } = searchObj;
  const { data: userData, error: userError } = useFetchConnectedUserData();
  const { data: organizationsData } = useFetchOrganizations();

  const change = useChangeOrganization();
  const { mutate: getPermissions, isLoading } = useGetPermissions();
  const { mutate: getPermissionsSidebar, isLoading: isLoadingSidebar } = useGetPermissions();

  const [userFilters, setUserFilters] = useState({});

  const changeUserFilters = useCallback(
    (filters) => {
      setUserFilters(filters);
      if (filters.siteId && filters.siteId !== siteId) {
        if (siteId === undefined) {
          navigate(
            `${pathname}${stringifyQuery({
              ...searchObj,
              siteId: filters.siteId
            })}`
          );
        } else {
          navigate(`${pathname}?siteId=${filters.siteId}`);
        }
      }
    },
    [navigate, pathname, searchObj, siteId]
  );

  useEffect(() => {
    if (siteId && siteId !== userFilters.siteId) {
      changeUserFilters({ siteId });
    }
  }, [changeUserFilters, siteId, userFilters.siteId]);

  const changeOrganization = useCallback(
    (organizationId) => {
      change({ organizationId });
    },
    [change]
  );

  const [userPermissions, setUserPermissions] = useState([]);
  const getPagePermissions = useCallback(
    (permissionsRequest) => {
      setUserPermissions([]);
      getPermissions(
        { permissions: permissionsRequest },
        {
          onSuccess: (response) => {
            const permissions = [];
            if (response?.data?.data) {
              response.data.data.forEach((p) => {
                if (p.granted === true) {
                  permissions.push(p.permission);
                }
              });
              setUserPermissions(permissions);
            }
          }
        }
      );
    },
    [getPermissions]
  );

  const [userSidebarPermissions, setUserSidebarPermissions] = useState([]);
  const getSidebarPermissions = useCallback(
    (permissionsRequest) => {
      setUserSidebarPermissions([]);
      getPermissionsSidebar(
        { permissions: permissionsRequest },
        {
          onSuccess: (response) => {
            const permissions = [];
            if (response?.data?.data) {
              response.data.data.forEach((p) => {
                if (p.granted === true) {
                  permissions.push(p.permission);
                }
              });
              setUserSidebarPermissions(permissions);
            }
          }
        }
      );
    },
    [getPermissionsSidebar]
  );

  const userPerPageCookieName = 'perPage';
  const [userPerPage, setUserPerPage] = useState(
    cookies?.[userPerPageCookieName] ? parseInt(cookies[userPerPageCookieName]) : null
  );
  useEffect(() => {
    if (userPerPage) {
      setCookie(userPerPageCookieName, parseInt(userPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPerPage]);

  if (!userData) {
    return !userError ? null : <ErrorPage />;
  }

  if (organizationId && userData?.organizationId) {
    if (organizationId !== userData.organizationId && !orgIsLoading) {
      setOrgIsLoading(true);
      change({ organizationId });
    }
  }

  const contextValue = {
    userOperator: userFilters.operator || 'or',
    userGroups: userFilters.groupIds || [],
    userFilters,
    changeUserFilters,
    userPerPage,
    setUserPerPage,
    userData,
    organizationId: userData.organizationId || '',
    organizations: organizationsData,
    changeOrganization,
    permissions: userData.permissions || [],
    getPagePermissions,
    userSidebarPermissions,
    userSidebarPermissionsIsLoading: isLoadingSidebar,
    getSidebarPermissions,
    userPermissions,
    userPermissionsIsLoading: isLoading,
    isAdmin: userData.isAdmin || false,
    isConnected: true,
    // isUnknown provient de `/me`, ce boolean est lier à un cas très spécifique de Keycloak (LS-720)
    isUnknown: userData.isUnknown || false,
    groupNotFound:
      userData.isGroupManager === true &&
      userData.managedGroupIds.length === 0 &&
      userData.managedSiteIds.length === 0,
    organizationNotFound: userData.organizationId === null,
    pageIsLoaded,
    setPageIsLoaded
  };

  return <Provider value={contextValue}>{children}</Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { UserContext, UserProvider };
