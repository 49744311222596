import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

class Toggle extends PureComponent {
  onToggle = (e) => {
    const { onNodeToggle, id } = this.props;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onNodeToggle(id);
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 32) {
      const { onNodeToggle, id } = this.props;
      onNodeToggle(id);
      e.preventDefault();
    }
  };

  render() {
    const { expanded, isLeaf } = this.props;
    if (isLeaf) {
      return <i role="button" tabIndex={-1} style={{ visibility: 'hidden' }} aria-hidden />;
    }

    const Icon = expanded ? ExpandMoreIcon : NavigateNextIcon;

    return (
      <Icon
        onClick={this.onToggle}
        onKeyDown={this.onKeyDown}
        aria-hidden
        tabIndex={-1}
        role="button"
      />
    );
  }
}

Toggle.propTypes = {
  expanded: PropTypes.bool,
  isLeaf: PropTypes.bool,
  onNodeToggle: PropTypes.func,
  id: PropTypes.string
};

export default Toggle;
