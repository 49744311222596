import styled, { keyframes } from 'styled-components';

const animateFade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const FadedMessage = styled.li`
  animation-name: ${animateFade};
  animation-duration: 1s;
  animation-iteration-count: 1;
  list-style-type: none;
`;

const animateDelay = keyframes`
  0% {
    height: 24px;
    opacity: 0;
    visibility: hidden;
  }

  100% {
    height: 24px;
    opacity: 1;
    visibility: visible;
  }
`;

export const DelayedLoader = styled.li`
  display: block;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  animation: ${animateDelay} 2s ease-in-out 1s forwards;
  list-style-type: none;
`;
