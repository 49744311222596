import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toArray } from 'lodash';

import { HOME_PATH } from 'eficia/constants/paths';
import { UserContext } from 'eficia/contexts/UserProvider';
import useNotification from 'eficia/hooks/useNotification';
import eficiaClient from 'eficia/services/eficiaClient';

// Groupes par défaut d'une nouvelle organisation
export const GROUPS_NAMES = [
  'organization.add.groups.name.1',
  'organization.add.groups.name.2',
  'organization.add.groups.name.3',
  'organization.add.groups.name.4',
  'organization.add.groups.name.5'
];

export default function useCreateOrganization() {
  const navigate = useNavigate();

  const client = eficiaClient();
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  const { changeOrganization } = useContext(UserContext);

  const fetcher = useCallback(
    (data) => {
      const params = {
        organizationName: data.name,
        groupNames: toArray(GROUPS_NAMES).map((name) => t(name)),
        countryIds: toArray(data.countryIds)
      };

      return client.user.addOrganization(params);
    },
    [client.user, t]
  );

  const mutation = useMutation(fetcher, {
    onSuccess: async (response) => {
      addNotification({
        message: t(`organization.create_modal.success.message`),
        type: 'success'
      });
      if (response?.data?.data?.organizationId) {
        navigate(HOME_PATH);

        changeOrganization(response.data.data.organizationId);
      }
    },
    onError: async (error) => {
      const { response } = error;
      let byPassError = false;

      if (response?.data?.errors) {
        if (Array.isArray(response.data.errors)) {
          response.data.errors.forEach((err) => {
            if (err === 'user_already_own_this_organization_name') {
              addNotification({
                message: t(
                  `organization.create_modal.error.user_already_own_this_organization_name`
                ),
                type: 'error'
              });

              byPassError = true;
            }
          });
        }
        if (response.data.errors.organizationName) {
          response.data.errors.organizationName.forEach((err) => {
            if (err === 'regex_failed_error') {
              addNotification({
                message: t('organization.view.edit.message.error.regex_failed_error'),
                type: 'error'
              });
              byPassError = true;
            }
          });
        }
      }
      if (!byPassError) {
        addNotification({
          message: t(`organization.create_modal.error.message`),
          type: 'error'
        });
      }
    }
  });

  return mutation.mutate;
}
