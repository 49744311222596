import React, { useContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ErrorBlock } from 'eficia/components/molecules/ErrorBlock';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';

export default function ErrorPage() {
  const [customStyles, setCustomStyles] = useState();

  const withoutVisibleSidebar = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { isPageTitlePresent, isSidebarPresent } = useContext(LayoutContext);

  const defaultStyles = {};

  useEffect(() => {
    if (isSidebarPresent) {
      setCustomStyles({
        width: withoutVisibleSidebar ? '100vw' : 'calc(100% + 48px)',
        // eslint-disable-next-line no-nested-ternary
        height: isPageTitlePresent
          ? 'calc(100vh - 226px)'
          : withoutVisibleSidebar
            ? 'calc(100vh - 150px)'
            : 'calc(100vh - 88px)',
        // eslint-disable-next-line no-nested-ternary
        marginTop: isPageTitlePresent ? -24 : withoutVisibleSidebar ? 0 : -90,
        marginLeft: -24,
        backgroundColor: 'var(--bg-contrast)'
      });
    } else {
      setCustomStyles({
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--bg-contrast)'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarPresent, isPageTitlePresent, withoutVisibleSidebar]);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={customStyles || defaultStyles}
      data-testid="error-page"
    >
      <ErrorBlock />
    </div>
  );
}
