import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from './Tooltip';
import { WrapperInfo } from './Tooltip.style';

export function TooltipInfo({ title, position = 'absolute', placement = 'top', ...props }) {
  return (
    <Tooltip title={title}>
      <WrapperInfo position={position} placement={placement} {...props}>
        <i className="fa-solid fa-circle-info" />
      </WrapperInfo>
    </Tooltip>
  );
}

TooltipInfo.propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  placement: PropTypes.string
};
