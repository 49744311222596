import axios from 'axios';
import { createBrowserHistory } from 'history';

import { HOME_PATH, LOGIN_PATH } from 'eficia/constants/paths';

const instance = axios.create({
  // injecter le cookie à chaque requête
  withCredentials: true
});

instance.interceptors.response.use(
  function (response) {
    // TODO: Formatter ici le retour d'api
    return response;
  },
  function (error) {
    const { response } = error;

    // Si l'utilisateur n'est pas connecté on le redirige sur le login.
    if (response?.status === 401) {
      if (response?.data?.errors?.length) {
        response.data.errors.forEach((err) => {
          if (err === 'user_not_found') {
            createBrowserHistory().push(`#${LOGIN_PATH}`);
            window.location.reload();
          }
          if (err === 'auth_no_cookie') {
            if (!window.location.hash.includes('?from=')) {
              if (window.location.hash.includes('#/login') || window.location.hash === '#/') {
                createBrowserHistory().push(`#${LOGIN_PATH}`);
              } else {
                createBrowserHistory().push(
                  `#${LOGIN_PATH}?from=${encodeURIComponent(window.location.hash.replace('#', ''))}`
                );
              }
              window.location.reload();
            }
          }
          if (err === 'cant_renew_token' || err === 'malformed_token' || err === 'expired_token') {
            if (!window.location.hash.includes('?from=')) {
              if (window.location.hash.includes('#/login') || window.location.hash === '#/') {
                createBrowserHistory().push(`#${LOGIN_PATH}?error=auth`);
              } else {
                createBrowserHistory().push(
                  `#${LOGIN_PATH}?error=auth&from=${encodeURIComponent(
                    window.location.hash.replace('#', '')
                  )}`
                );
              }
              window.location.reload();
            }
          }
        });
      }
    }
    if (response?.status === 403) {
      if (response?.data?.errors?.length) {
        response.data.errors.forEach((err) => {
          if (
            err === 'organization_not_found_forced_logout' &&
            window.location.hash !== '#/login'
          ) {
            // si un siteId est présent dans l'url, on redirige vers la page d'accueil
            // createBrowserHistory().push(`#/organization-not-found`);
            window.location.reload();
          }

          if (
            err === 'organization_not_found' &&
            window.location.hash !== '#/organization-not-found'
          ) {
            // si un siteId est présent dans l'url, on redirige vers la page d'accueil
            createBrowserHistory().push(`#/organization-not-found`);
            window.location.reload();
          }
        });
      }
    }

    if (response?.status === 404) {
      if (response?.data?.errors?.length) {
        response.data.errors.forEach((err) => {
          if (err === 'site_not_found') {
            // si un siteId est présent dans l'url, on redirige vers la page d'accueil
            if (window.location.hash.includes('?siteId=')) {
              createBrowserHistory().push(`#${HOME_PATH}`);
              window.location.reload();
            }
          }
        });
      }
    }

    // retourner l'erreur
    return Promise.reject(error);
  }
);

export default instance;
