import { USER_API } from './config/constants';
import queryConfig from './config/queryConfig';
import axiosConfig from './config/axiosConfig';

const api = 'organization';
const baseUrl = USER_API;

export default function organizationApi(isConnected = false) {
  return {
    async fetchOne(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchOne',
        params
      });
    },
    async edit(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'edit',
        params
      });
    },
    // anonymous
    async change(params) {
      const query = await axiosConfig.post(`${USER_API}/change-organization`, params);
      return query;
    },
    // anonymous
    async fetch(params) {
      const query = await axiosConfig.post(`${USER_API}/get-organizations`, params);
      return query;
    }
  };
}
