import React from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { Badge } from 'eficia/components/atoms/Badge';
import { Button } from 'eficia/components/atoms/Button';

import { MarkdownContainer, Message } from './ChatbotMessage.style';

export function ChatbotMessage({ data, sender, onSubmit }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      {data.map((line, index) => {
        let body = '';
        if (typeof line === 'string') {
          if (sender === 'bot') {
            // Bot is supporting Markdown
            body = (
              <MarkdownContainer data-testid="chatbot-message-text">
                <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>
                  {line}
                </Markdown>
              </MarkdownContainer>
            );
          } else {
            // User
            body = <span data-testid="chatbot-message-text">{line}</span>;
          }
        } else if (line?.object) {
          if (line.object === 'badge') {
            body = <Badge label={line.label} data-testid="chatbot-message-badge" />;
          } else if (line.object === 'button') {
            body = (
              <Button
                size="small"
                variant="activable"
                color="inactive"
                startIcon={<i className={`fa-solid fa-${line.icon}`} />}
                label={line.label}
                onClick={() => onSubmit(line.value || line.label)}
                data-testid="chatbot-message-button"
              />
            );
          } else if (line.object === 'button-group' && line.buttons?.length) {
            body = (
              <Grid container spacing={2} data-testid="chatbot-message-button-group">
                {line.buttons.map((button, index) => (
                  <Grid item xs={6} key={index}>
                    <Button
                      size="small"
                      variant="activable"
                      color="inactive"
                      startIcon={
                        <div
                          style={{
                            paddingBottom: '8px',
                            marginLeft: '8px',
                            transform: 'scale(1.25)'
                          }}
                        >
                          <i className={`fa-solid fa-${button.icon}`} />
                        </div>
                      }
                      label={button.label}
                      onClick={() => onSubmit(button.value || button.label)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '10px',
                        lineHeight: '18px',
                        minHeight: '120px'
                      }}
                      data-testid="chatbot-message-button-group-button"
                    />
                  </Grid>
                ))}
              </Grid>
            );
          }
        }
        if (!body) {
          return null;
        }

        return (
          <Message key={index} data-testid="chatbot-message" className={`sender-${sender}`}>
            {body}
          </Message>
        );
      })}
    </div>
  );
}

ChatbotMessage.propTypes = {
  data: PropTypes.array.isRequired,
  sender: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
