import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';

import { DesktopOverlay } from './components/desktop/DesktopRightSidebar.style';
import { MobileOverlay } from './components/mobile/MobileRightSidebar.style';
import DesktopRightSidebar from './components/desktop/DesktopRightSidebar';
import MobileRightSidebar from './components/mobile/MobileRightSidebar';

export default function RightSidebar({ children }) {
  const isTabletOrMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const { rightSidebarContent, setRightSidebarContent } = useContext(LayoutContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        transform: rightSidebarContent ? 'translateZ(0)' : 'none'
      }}
      data-testid={rightSidebarContent ? 'opened-right-sidebar' : 'closed-right-sidebar'}
    >
      <div style={{ display: 'flex', flex: '1', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            ...(rightSidebarContent && { maxHeight: '100vh', overflowY: 'hidden' })
          }}
        >
          {children}
        </div>
        {!!rightSidebarContent && !isTabletOrMobile && (
          <DesktopOverlay onClick={() => setRightSidebarContent(null)} />
        )}
        {!!rightSidebarContent && isTabletOrMobile && (
          <MobileOverlay onClick={() => setRightSidebarContent(null)} />
        )}
      </div>
      {rightSidebarContent !== undefined && !isTabletOrMobile && <DesktopRightSidebar />}
      {rightSidebarContent !== undefined && isTabletOrMobile && <MobileRightSidebar />}
    </div>
  );
}

RightSidebar.propTypes = {
  children: PropTypes.node
};
