import { USER_API, SITES_API } from './config/constants';
import queryConfig from './config/queryConfig';
import axiosConfig from './config/axiosConfig';

const api = 'user';
const baseUrl = USER_API;

export default function userApi(isConnected = false) {
  return {
    async addOrganization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'addOrganization',
        params
      });
    },
    async getEcowattOptout() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'getEcowattOptout'
      });
    },
    async editEcowattOptout(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editEcowattOptout',
        params
      });
    },
    async deleteMyAccount(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteMyAccount',
        params
      });
    },
    async deleteUser(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteUser',
        params
      });
    },
    async leaveOrganization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'leaveOrganization',
        params
      });
    },
    async fetch() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch'
      });
    },
    async edit(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'edit',
        params
      });
    },
    async editLanguage(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editLanguage',
        params
      });
    },
    async logout() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'logout'
      });
    },

    async contact(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'contact',
        params
      });
    },
    async contactEnergyCenter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: SITES_API,
        endpoint: 'contactEnergyCenter',
        params
      });
    },
    // anonymous
    async fetchLanguages(params) {
      const query = await axiosConfig.post(`${USER_API}/get-languages`, params);
      return query;
    },
    // anonymous
    async getPermissions(params) {
      const query = await axiosConfig.post(`${USER_API}/get-permission-list`, params);
      return query;
    },
    // anonymous
    async isConnected() {
      const query = await axiosConfig.post(`${USER_API}/is-connected`);
      return query;
    },
    // anonymous
    async getUrlAuthorization(params) {
      const query = await axiosConfig.post(`${USER_API}/get-url-authorization`, params);
      return query;
    },
    // anonymous
    async authorize(params) {
      const query = await axiosConfig.post(`${USER_API}/authorize`, params);
      return query;
    },
    // anonymous
    async me() {
      const query = await axiosConfig.post(`${USER_API}/me`);
      return query;
    },
    // anonymous
    async invitationRegistration(params) {
      const query = await axiosConfig.post(`${USER_API}/invitation-registration`, params);
      return query;
    },
    // anonymous
    async loginAs(params) {
      const query = await axiosConfig.post(`${USER_API}/login-as`, params);
      return query;
    },
    // anonymous
    async fetchOrganizationsByEmail(params) {
      const query = await axiosConfig.post(`${USER_API}/get-organizations-by-email`, params);
      return query;
    }
  };
}
