import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'eficia/contexts/UserProvider';
import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchSitesByGroups(filters) {
  const client = eficiaClient();
  const { isUnknown, organizationNotFound } = useContext(UserContext);
  const { groupIds, operator } = filters;
  let labels = null;
  let withGasMeters = null;

  const fetcher = useCallback(() => {
    const params = {};

    if (isUnknown || organizationNotFound) {
      return null;
    }
    if (!groupIds) {
      return null;
    }

    if (groupIds.length > 0) {
      params.groupIds = groupIds;
      params.intersect = operator === 'and';
    }

    return client.sites.fetchFullByGroupId(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.sites, groupIds, operator]);

  const { data, error, isFetching, refetch } = useQuery(
    ['user-groups-sites', groupIds, operator],
    fetcher
  );

  if (data?.data?.data?.sites) {
    labels = data.data.data.sites.map((site) => {
      return {
        label: site.name,
        value: site.id
      };
    });

    withGasMeters = data.data.data.sites
      .filter((site) => site.hasAtLeastOneGasMeterRemoteReadable)
      .map((site) => {
        return {
          label: site.name,
          value: site.id
        };
      });
  }

  return {
    data: {
      data: data?.data?.data
    },
    labels,
    withGasMeters,
    error,
    isFetching,
    refetch
  };
}
