import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Modal from 'eficia/components/organisms/Modal/Modal';
import { UserContext } from 'eficia/contexts/UserProvider';
import FormInput from 'eficia/views/forms/FormInput';

import useContact from 'eficia/views/login/services/useContact';
import useContactEnergyCenter from 'eficia/views/login/services/useContactEnergyCenter';

import SitesECFormSingleSelect from 'eficia/views/lists/SitesECFormSingleSelect';

export default function ContactFormModal({ energyCenterLang, isHandledByEc, onClose, ...rest }) {
  const { t } = useTranslation();
  const { userData, userFilters } = useContext(UserContext);

  const contact = useContact();
  const contactEnergyCenter = useContactEnergyCenter(energyCenterLang);

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
      object: '',
      siteId: userFilters.siteId || ''
    },
    shouldUnregister: false
  });

  const {
    formState,
    trigger,
    control,
    handleSubmit: onUseFormSubmit,
    formState: { errors },
    watch
  } = formProps;

  const message = watch('message', '');

  const handleContact = () => {
    if (isHandledByEc) {
      contactEnergyCenter({
        message: watch('message'),
        object: watch('object'),
        siteId: watch('siteId'),
        email: userData.email
      });
    } else {
      contact({
        message: watch('message'),
        object: watch('object'),
        email: userData.email
      });
    }
    onClose();
  };

  return (
    <Modal
      onSubmit={onUseFormSubmit(handleContact)}
      onClose={onClose}
      isSubmitButtonDisabled={!formState.isValid || !formState.isDirty}
      testId="contact-form-modal"
      {...rest}
    >
      <Grid container justifyContent="center" style={{ gap: 16 }}>
        <Grid item xs={12}>
          <Typography>{t('contact_form.modal.content.text')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              gap: 10,
              borderRadius: 8,
              border: '1px solid #F4772E',
              backgroundColor: '#FFF2EA',
              padding: '12px 16px 12px 15px',
              position: 'relative',
              top: -3,
              fontSize: 14,
              lineHeight: '18px'
            }}
          >
            <Typography>{t('contact_form.modal.content.warning')}</Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <FormInput
            autoFocus
            placeholder={t('contact_form.object.label')}
            name="object"
            control={control}
            error={errors.object}
          />
        </Grid>
        {isHandledByEc && (
          <Grid item xs={12}>
            <SitesECFormSingleSelect
              mode="simpleSelect"
              formProps={formProps}
              control={control}
              name="siteId"
              label={t('contact_form.site.label')}
              placeholder={t('contact_form.site.placeholder')}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormInput
            autoFocus
            placeholder={t('contact_form.message.placeholder')}
            name="message"
            control={control}
            error={errors.message}
            maxLength={800}
            multiline
            rows={10}
            required
            rules={{
              required: true
            }}
            onChange={() => {
              trigger('message');
            }}
          />
          {message.length >= 800 ? (
            <span style={{ color: 'red', float: 'right' }}>
              {t('contact_form_modal.content.length', {
                length: message.length
              })}
            </span>
          ) : (
            <span style={{ color: 'black', float: 'right' }}>
              {t('contact_form_modal.content.length', {
                length: message.length
              })}
            </span>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
}

ContactFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isHandledByEc: PropTypes.bool.isRequired,
  energyCenterLang: PropTypes.string
};
